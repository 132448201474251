import {IPDRefList} from '../fieldTypes';
import {JSONSchemaType} from 'ajv';

export const getRefListSchema = (fd: IPDRefList): JSONSchemaType<Array<string>> => {
  return {
    type: 'array',
    items: {
      type: 'string'
    },
    minItems: fd.minItems ?? 1,
    maxItems: fd.maxItems ?? undefined
  };
};
