/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {PreviewImage} from './PreviewImage';
import {FC, Fragment, useMemo, useState} from 'react';
import {ReadonlyCanvasAdapter} from '../canvas/ReadonlyCanvasAdapter';
import {Line, Polygon, IOverlayParams} from '../canvas/types';
import {IPresetArea, usePresetAreas} from '../../hooks/usePresetAreas';

export const VisualAreas: FC<{cameraId: string; presetId: string | null; className?: string}> = ({
  cameraId,
  presetId,
  className
}) => {
  const areasMap = usePresetAreas();
  const [overlayParams, setOverlayParams] = useState<IOverlayParams | null>(null);
  const initState = useMemo(() => {
    const polygons: Polygon[] = [];
    const lines: Line[] = [];

    const areas = [...areasMap.values()];
    areas.forEach((a) => {
      switch (a.areaType) {
        case 'Line':
          try {
            lines.push(presetAreaToLine(a));
          } catch (e) {
            console.error('Could not convert PresetArea to Polygon', a);
          }
          break;
        case 'Polygon':
          try {
            polygons.push(presetAreaToPolygon(a));
          } catch (e) {
            console.error('Could not convert PresetArea to Polygon', a);
          }
          break;
      }
    });

    return {
      itemStore: {
        polygonStore: {
          items: polygons,
          editableLimit: 0
        },
        lineStore: {
          items: lines,
          editableLimit: 0
        }
      }
    };
  }, [areasMap]);
  return (
    <Fragment>
      <PreviewImage cameraId={cameraId} presetId={presetId} className={className} onOverlayReady={setOverlayParams} />
      {overlayParams && <ReadonlyCanvasAdapter overlayParams={overlayParams} initState={initState} />}
    </Fragment>
  );
};

const presetAreaToPolygon = (presetArea: IPresetArea): Polygon => {
  const {id, title, area, direction, directionType} = presetArea;
  return {
    id: id,
    editable: false,
    name: title,
    points: area.map(([x, y], index) => ({index, x, y})),
    direction: !!direction ? {x: direction[1], y: direction[0]} : undefined,
    directed: directionType === 'Directed',
    style: {
      strokeColor: 'rgba(60, 114, 255, 1)'
    }
  };
};

const presetAreaToLine = (area: IPresetArea): Line => {
  const [start, end] = area.area.map(([x, y], index) => ({index, x, y}));
  return {
    id: area.id,
    editable: false,
    name: area.title,
    start,
    end,
    directed: area.directionType === 'Directed',
    style: {
      strokeColor: 'rgba(60, 114, 255, 1)'
    }
  };
};
