import {IAssignmentType} from '../../models/IAssignmentType';
import {IAssignment} from '../../models/IAssignment';
import {IFormValues} from './IFormValues';
import {getEmptyValue} from '../../dynamic-form/getEmptyValue';

export const getInitialValues = (
  cameraId: string,
  assignmentType: IAssignmentType | undefined,
  selectedAssignment?: IAssignment,
  prevValues?: IFormValues
): IFormValues => {
  const initialParameters: IFormValues['parameters'] = {};
  if (assignmentType) {
    Object.entries(assignmentType.parameters.properties).forEach(([name, description]) => {
      initialParameters[name] =
        (selectedAssignment?.parameters as any)?.[name] ??
        ('default' in description && typeof description.default !== 'undefined'
          ? description.default
          : getEmptyValue(description.type));
    });
  }
  return {
    entityId: selectedAssignment?.entityId ?? cameraId,
    entityType: selectedAssignment?.entityType ?? 'Camera',

    startAfterCreate: true,

    assignmentTypeId: selectedAssignment?.assignmentTypeId.toString() ?? assignmentType?.id ?? null,
    title: selectedAssignment?.title ?? prevValues?.title ?? '',
    priorityId: selectedAssignment?.priorityId ?? prevValues?.priorityId ?? null,

    parameters: initialParameters
  };
};
