import {IPDEnumList} from '../fieldTypes';
import {JSONSchemaType} from 'ajv';

export const getEnumListSchema = (fd: IPDEnumList): JSONSchemaType<Array<string | number | boolean>> => {
  return {
    type: 'array',
    items: {
      type: typeof fd.enum[0] as 'string' | 'number' | 'boolean',
      enum: fd.enum
    },
    minItems: fd.minItems ?? 1,
    maxItems: fd.maxItems ?? undefined
  };
};
