import {IPropertyType, IPropertyValue} from './fieldTypes';

export const getEmptyValue = (type: IPropertyType): IPropertyValue | null => {
  switch (type) {
    case 'string':
      return '';
    case 'number':
      return null;
    case 'enum':
      return null;
    case 'enumList':
      return [];
    case 'ref':
      return null;
    case 'refList':
      return [];
    case 'object':
      return null;
    case 'boolean':
      return false;
    // TODO implement
    case 'range':
    // TODO implement
    case 'dateTime':
    // TODO implement
    case 'dateTimeRange':
    default:
      return null;
  }
};
