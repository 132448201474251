import {useEffect, useMemo, useState} from 'react';

export const useDialogVisible = <T extends (...args: any[]) => void>(onHide: T): [boolean, T] => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
  }, []);
  return [
    visible,
    useMemo(() => {
      return new Proxy(onHide, {
        apply(target: T, thisArg, argArray) {
          setVisible(false);
          setTimeout(() => {
            Reflect.apply(target, thisArg, argArray);
          }, 200);
        }
      });
    }, [onHide])
  ];
};
