import React, {FC, useContext} from 'react';
import {defaultLocale, ILocale} from '../locale';

const LocaleCtx = React.createContext(defaultLocale);

export const useLocale = () => useContext(LocaleCtx);

export const LocaleProvider: FC<{value?: ILocale}> = ({value = defaultLocale, children}) => {
  return <LocaleCtx.Provider value={value}>{children}</LocaleCtx.Provider>;
};

const LangCtx = React.createContext('ru');

export const useLang = () => useContext(LangCtx);

export const LangProvider: FC<{value?: string}> = ({value = 'ru', children}) => {
  return <LangCtx.Provider value={value}>{children}</LangCtx.Provider>;
};
