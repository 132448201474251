/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import React, {FC, useRef, useLayoutEffect, useState} from 'react';
import {ICameraPreset} from '../../models/ICameraPreset';
import {useWidgetContext} from '../../Root';
import {Dialog} from 'primereact/dialog';
import {useDialogVisible} from '../../hooks/useDialogVisible';
import {genElementId} from '../../utils/genElementId';
import {IWidgetProps} from '../../IWidgetProps';
import {Widget} from '@netvision/lib-widget-renderer';

export const CalibrationEditor: FC<{preset: ICameraPreset; onHide: () => void}> = ({preset, onHide: _onHide}) => {
  const [visible, onHide] = useDialogVisible(_onHide);
  const [id] = useState(genElementId);
  return (
    <Dialog
      id={id}
      css={$dialog}
      appendTo={document.body}
      visible={visible}
      showHeader={false}
      onHide={onHide}
      closable={false}
      modal={true}>
      <CalibrateAdapter preset={preset} onClose={onHide} />
    </Dialog>
  );
};

const $dialog = css`
  max-height: calc(100vh - 60rem / var(--bfs));
  width: calc(702rem / var(--bfs));

  & .p-dialog-content {
    border-radius: var(--border-radius);
  }
`;

const CalibrateAdapter: FC<{preset: ICameraPreset; onClose: () => void}> = ({preset, onClose}) => {
  const {mountChildren, areas} = useWidgetContext();
  const ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    const calibrate = getAreasChildren(areas);
    if (node && calibrate) {
      return mountChildren(node, [
        {
          ...calibrate,
          props: {
            ...calibrate.props,
            preset,
            onClose
          }
        }
      ]);
    }
    return undefined;
    // eslint-disable-next-line
  }, []);
  return <div ref={ref} />;
};

function getAreasChildren(areas: IWidgetProps['areas']): Widget | null {
  let calibrate = null;
  areas?.forEach((area) => {
    if (area.name === 'calibrate' && area.children && area.children.length > 0) {
      calibrate = area.children[0];
    }
  });
  return calibrate;
}
