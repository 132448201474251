import {IPDObject, IPropertyDesc} from '../fieldTypes';
import {JSONSchemaType} from 'ajv';
import {getNumberSchema} from './getNumberSchema';
import {getStringSchema} from './getStringSchema';
import {getBooleanSchema} from './getBooleanSchema';
import {getEnumSchema} from './getEnumSchema';
import {getEnumListSchema} from './getEnumListSchema';
import {getRefSchema} from './getRefSchema';
import {getRefListSchema} from './getRefListSchema';

export const getObjectSchema = (fd: IPDObject): JSONSchemaType<{}> => {
  const requiredSet = new Set(fd.required);
  const properties: Record<string, JSONSchemaType<any>> = {};
  Object.entries(fd.properties).forEach(([key, value]) => {
    const schema = selectSchema(value, requiredSet.has(key));
    if (schema) {
      properties[key] = schema;
    }
  });
  return {
    type: 'object',
    additionalProperties: false,
    required: fd.required as [],
    properties
  };
};

const selectSchema = (fd: IPropertyDesc, required: boolean) => {
  switch (fd.type) {
    case 'object':
      return getObjectSchema(fd);
    case 'number':
      return getNumberSchema(fd);
    case 'string':
      return getStringSchema(fd, required);
    case 'boolean':
      return getBooleanSchema(fd);
    case 'enum':
      return getEnumSchema(fd);
    case 'enumList':
      return getEnumListSchema(fd);
    case 'ref':
      return getRefSchema(fd);
    case 'refList':
      return getRefListSchema(fd);
    default:
      return null;
  }
};
