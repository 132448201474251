/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {Dialog} from 'primereact/dialog';
import {FC} from 'react';
import {LivePlayerAdapter} from './LivePlayerAdapter';
import {useDialogVisible} from '../../hooks/useDialogVisible';
import {useLocale} from '../../providers/LocaleProvider';

export const PresetEditor: FC<{cameraId: string; onHide: () => void}> = ({cameraId, onHide: _onHide}) => {
  const locale = useLocale().presetEditor;
  const [visible, onHide] = useDialogVisible(_onHide);
  return (
    <Dialog appendTo={document.body} css={$dialog} visible={visible} onHide={onHide} header={locale.header}>
      <LivePlayerAdapter css={$player} cameraId={cameraId} />
    </Dialog>
  );
};

const $dialog = css`
  width: calc(100vw - 60rem / var(--bfs));
  max-height: unset;
  max-width: unset;
  height: calc(100vh - 60rem / var(--bfs));

  .p-dialog-content {
    overflow: hidden;
    border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
`;

const $player = css`
  width: 100%;
  height: calc(100vh - 60rem / var(--bfs) - 82.4rem / var(--bfs));
`;
