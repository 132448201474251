import {IEntity, listEntities} from '@netvision/lib-api';
import {IPtzValues} from './models/IPtzValues';

interface IPtzData {
  status: {
    position: IPtzValues;
  };
  ptzSettings: IPtzSettings;
}

interface ICamera extends IEntity, IPtzData {}

export interface IPtzSettings {
  delay?: number;
  threshold?: IPtzValues;
}

export function getPTZData(cameraId: string): Promise<IPtzData | null> {
  return listEntities({
    id: cameraId,
    type: 'Camera',
    attrs: 'status,ptzSettings',
    keyValues: true
  }).then((res) => {
    if (res.results.length === 1) {
      const camera = res.results[0];
      if ('status' in camera && 'ptzSettings' in camera) {
        const {ptzSettings, status} = camera as ICamera;
        return {
          status,
          ptzSettings
        };
      } else {
        console.error(Error('Camera does not have required ptz fields: "status", "ptzSettings"'), res);
        return null;
      }
    } else {
      console.error(Error('Unexpected result, expect exactly one entity'), res);
      return null;
    }
  });
}
