import React, {createContext, Dispatch, FC, SetStateAction, useContext, useState} from 'react';

type IState<S> = [S, Dispatch<SetStateAction<S>>];

const ctx = createContext<IState<string | null>>(null!);

export const CurrentPresetProvider: FC = ({children}) => {
  const state = useState<string | null>(null);
  return <ctx.Provider value={state}>{children}</ctx.Provider>;
};

export const useCurrentPreset = () => {
  return useContext(ctx);
};
