import React, {useContext, useLayoutEffect, useMemo, useState} from 'react';
import {fetchAssignment} from './api-assignment';
import {AssignmentForm, IAssignmentFormProps} from './components/form/Form';
import {EntityCacheProvider} from './hooks/useEntityList';
import {IWidgetProps} from './IWidgetProps';
import {LocaleProvider} from './providers/LocaleProvider';

const WidgetContext = React.createContext<Pick<IWidgetProps, 'mountChildren' | 'areas'>>(null!);

export function useWidgetContext() {
  return useContext(WidgetContext);
}

export default function Root(widgetProps: IWidgetProps) {
  const {mountChildren, areas} = widgetProps;
  const {cameraId, assignmentId, onClose} = widgetProps.props!;
  const widgetContextValue = useMemo(
    () => ({
      mountChildren,
      areas
    }),
    [mountChildren, areas]
  );
  const [data, setData] = useState<IAssignmentFormProps | null>(null);
  const [_, setAssignmentError] = useState(false);
  const [__, setLoading] = useState(false);
  useLayoutEffect(() => {
    if (assignmentId) {
      setLoading(true);
      setAssignmentError(false);
      let aborted = false;
      fetchAssignment(assignmentId).then((selectedAssignment) => {
        if (aborted) return;

        if (!selectedAssignment) {
          setAssignmentError(true);
        } else {
          setData({cameraId, selectedAssignment: selectedAssignment, onClose});
        }
      });
      return () => {
        aborted = true;
      };
    } else {
      setData({cameraId, selectedAssignment: undefined, onClose});
      return undefined;
    }
  }, [cameraId, assignmentId, onClose]);
  return (
    <WidgetContext.Provider value={widgetContextValue}>
      <LocaleProvider>
        <EntityCacheProvider>
          {data ? (
            <AssignmentForm
              cameraId={data.cameraId}
              selectedAssignment={data.selectedAssignment}
              onClose={data.onClose}
            />
          ) : null}
        </EntityCacheProvider>
      </LocaleProvider>
    </WidgetContext.Provider>
  );
}
