/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {Form, Formik, FormikConfig, FormikErrors, FormikProps, useFormikContext} from 'formik';
import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Ajv, {ErrorObject} from 'ajv';
import {get, omit, set} from 'lodash-es';
import {IParamDesc} from '../../models/IParamDesc';
import {IPDBoolean, IPDObject, IPDRef, IPDRefList, IPDString, IPropertyDesc} from '../../dynamic-form/fieldTypes';
import {IAssignmentType} from '../../models/IAssignmentType';
import {useLocale} from '../../providers/LocaleProvider';
import {useCachedEntity} from '../../hooks/useEntityList';
import {Button} from 'primereact/button';
import {AreaField} from './additionalFields/AreaField';
import {PresetField} from './additionalFields/PresetField';
import {PresetAreasContextProvider} from '../../hooks/usePresetAreas';
import {VisualAreas} from '../preview/VisualAreas';
import {useAjvLocale} from '../../hooks/useAjvLocale';
import {getInitialValues} from './getInitialValues';
import {BooleanField, FieldsLocaleProvider, IFieldsLocale, RefField, StringField} from '../../dynamic-form/fields';
import {ObjectField} from '../../dynamic-form/fields/ObjectField';
import {IFormValues} from './IFormValues';
import {getObjectSchema} from '../../dynamic-form/validateValues/getObjectSchema';
import {createAssigment, patchAssignment, startAssignment} from '../../api-assignment';
import {slick} from '../../utils/slick';
import {FormikSetterProvider, useFormikSetter} from '../../lib/FormikSetterProvider';
import {getEmptyValue} from '../../dynamic-form/getEmptyValue';
import {CurrentPresetProvider, useCurrentPreset} from './CurrentPresetProvider';
import {IAssignment} from '../../models/IAssignment';

type IFormPD = {
  type: 'object';
  required: ['startAfterCreate', 'title', 'assignmentTypeId', 'priorityId', 'parameters'];
  properties: {
    entityId: IPDString;
    entityType: IPDString;
    startAfterCreate: IPDBoolean;
    title: IPDString;
    assignmentTypeId: IPDRef;
    priorityId: IPDRef;
    parameters: IPDObject;
  };
};

export interface IAssignmentFormProps {
  cameraId: string;
  selectedAssignment: IAssignment | undefined;
  onClose: () => void;
}

export const AssignmentForm: FC<IAssignmentFormProps> = ({cameraId, selectedAssignment, onClose}) => {
  const lang = 'ru';
  const locale = useLocale();
  const ajvLocale = useAjvLocale(lang);

  const [assignmentTypeId, setAssignmentTypeId] = useState<string | null>(selectedAssignment?.assignmentTypeId ?? null);

  const selectedAssignmentType = useCachedEntity<IAssignmentType>('AssignmentType', assignmentTypeId ?? undefined);

  const prevValuesRef = useRef<IFormValues | undefined>(undefined);

  const onChange = useCallback((formikProps: FormikProps<IFormValues>) => {
    setAssignmentTypeId(formikProps.values.assignmentTypeId);
    prevValuesRef.current = formikProps.values;
  }, []);

  const initialValues = useMemo(() => {
    return getInitialValues(cameraId, selectedAssignmentType, selectedAssignment, prevValuesRef.current);
  }, [cameraId, selectedAssignmentType, selectedAssignment]);

  const [resetId, setResetId] = useState(0);

  const onReset = useCallback(() => setResetId((prev) => prev + 1), []);

  const formParamDesc = useMemo((): IFormPD => {
    return {
      type: 'object',
      required: ['startAfterCreate', 'title', 'assignmentTypeId', 'priorityId', 'parameters'],
      properties: {
        ...basePropDesc,
        parameters: {
          type: 'object',
          required: selectedAssignmentType?.parameters.create.required ?? [],
          properties: (selectedAssignmentType?.parameters ?? parametersFallback()).properties
        }
      }
    };
    // refresh only after reset
    // eslint-disable-next-line
  }, [resetId]);

  const fieldsLocale = useMemo((): IFieldsLocale => {
    return {
      properties: {
        ...locale.commonFieldsLocale,
        parameters: selectedAssignmentType?.localeRu?.parameters ?? {}
      }
    };
  }, [selectedAssignmentType, locale]);

  const onSubmit = useMemo<FormikConfig<IFormValues>['onSubmit']>(() => {
    return async (values, formikHelpers) => {
      try {
        formikHelpers.setStatus(locale.sending);
        if (selectedAssignment?.id) {
          if (values.priorityId) {
            await patchAssignment(selectedAssignment.id, {
              title: values.title,
              priorityId: values.priorityId,
              parameters: values.parameters
            });
          }
        } else {
          if (values.assignmentTypeId && values.priorityId) {
            const id = await createAssigment({
              entityType: values.entityType,
              entityId: values.entityId,
              title: values.title,
              assignmentTypeId: values.assignmentTypeId,
              priorityId: values.priorityId,
              parameters: values.parameters
            });
            // TODO
            // timeout is a temporary solution, startAfterCreate should be a request parameter
            if (values.startAfterCreate) {
              setTimeout(() => startAssignment(id), 3000);
            }
          }
        }
        formikHelpers.setStatus(locale.sendingSuccess);
        await new Promise((resolve) => setTimeout(resolve, 500));
        typeof onClose === 'function' && onClose();
      } catch (e) {
        formikHelpers.setStatus(locale.sendingError);
      }
    };
  }, [locale, selectedAssignment, onClose]);

  const validate = useMemo((): FormikConfig<IFormValues>['validate'] => {
    const ajv = new Ajv({allErrors: true});
    const schema = getObjectSchema(formParamDesc);
    const ajvValidate = ajv.compile(schema);
    return (values) => {
      const slicked = slick(values);
      if (ajvValidate(slicked)) {
        return {};
      } else {
        const errors = ajvValidate.errors ?? [];
        ajvLocale(errors);
        return transformErrors(errors, fieldsLocale, {
          required: locale.required,
          valueIs: locale.valueIs
        });
      }
    };
  }, [formParamDesc, ajvLocale, fieldsLocale, locale]);

  return (
    <PresetAreasContextProvider>
      <CurrentPresetProvider>
        <Formik
          enableReinitialize
          onReset={onReset}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}>
          <FormikSetterProvider>
            <FieldsLocaleProvider value={fieldsLocale}>
              <InnerForm
                cameraId={cameraId}
                selectedAssignment={selectedAssignment}
                onClose={onClose}
                propertyDescription={formParamDesc}
                onChange={onChange}
              />
            </FieldsLocaleProvider>
          </FormikSetterProvider>
        </Formik>
      </CurrentPresetProvider>
    </PresetAreasContextProvider>
  );
};

const basePropDesc = {
  entityId: {type: 'string'},
  entityType: {type: 'string'},
  startAfterCreate: {
    type: 'boolean'
  },
  title: {
    type: 'string',
    minLength: 3,
    maxLength: 32,
    pattern: '^[A-zA-я0-9 .,-]*$'
  },
  assignmentTypeId: {
    type: 'ref',
    entityType: 'AssignmentType'
  },
  priorityId: {
    type: 'ref',
    entityType: 'AssignmentPriority',
    orderBy: 'value'
  }
} as const;

const parametersFallback: () => IParamDesc = () => ({
  order: [],
  create: {
    required: [],
    excluded: []
  },
  properties: {}
});

interface InnerFormProps {
  propertyDescription: IFormPD;
  onChange: (formikProps: FormikProps<IFormValues>) => void;
}

const InnerForm: FC<IAssignmentFormProps & InnerFormProps> = ({
  selectedAssignment,
  onClose,
  propertyDescription,
  onChange
}) => {
  const {setFieldValue} = useFormikSetter();
  const [currentPresetId] = useCurrentPreset();

  const locale = useLocale();

  const formikProps = useFormikContext<IFormValues>();

  useEffect(() => {
    onChange(formikProps);
  }, [formikProps, onChange]);

  const paramDesc = propertyDescription.properties.parameters;

  const paramProperties = propertyDescription.properties.parameters.properties;
  const paramPropertiesList = useMemo(() => Object.entries(paramProperties), [paramProperties]);

  const presetField = useMemo(() => {
    const fieldNames = paramPropertiesList.filter((v: [string, IPropertyDesc]): v is [string, IPDRef] => {
      const [, value] = v;
      return value.type === 'ref' && value.entityType === 'CameraPreset';
    });
    if (fieldNames.length === 0) {
      console.warn('Could not find preset parameter', paramPropertiesList);
      return undefined;
    }
    return fieldNames[0];
  }, [paramPropertiesList]);

  const areaFields = useMemo(() => {
    return paramPropertiesList.filter((v: [string, IPropertyDesc]): v is [string, IPDRefList | IPDRef] => {
      const [, value] = v;
      return (value.type === 'refList' || value.type === 'ref') && value.entityType === 'PresetArea';
    });
  }, [paramPropertiesList]);

  const restFields = useMemo(() => {
    let properties;
    if (presetField) {
      properties = omit(paramDesc.properties, [presetField[0], ...areaFields.map(([name]) => name)]);
    } else {
      properties = paramDesc.properties;
    }
    return {
      ...paramDesc,
      properties
    };
  }, [paramDesc, presetField, areaFields]);

  const cameraId = formikProps.values.entityId;
  const presetId = presetField ? (formikProps.values.parameters[presetField[0]] as string) : null;

  useEffect(() => {
    areaFields.forEach(([name, field]) => {
      setFieldValue(`parameters.${name}`, getEmptyValue(field.type));
    });
    // eslint-disable-next-line
  }, [presetId]);
  useEffect(() => {
    if (presetId === currentPresetId) {
      setFieldValue('startAfterCreate', true);
    } else {
      setFieldValue('startAfterCreate', false);
    }
  }, [presetId, currentPresetId, setFieldValue]);

  const status = getFirstError(formikProps.errors) ?? formikProps.status;

  const isSubmitDisabled =
    formikProps.isSubmitting ||
    formikProps.isValidating ||
    !formikProps.isValid ||
    (!!selectedAssignment ? !formikProps.dirty : false);

  const mainAreaField = areaFields.slice(0, 1)[0];

  const otherAreaFields = areaFields.slice(1);

  type ILayoutType = 'md' | 'sm' | 'xs';
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [layout, setLayout] = useState<ILayoutType | null>(null);

  useEffect(() => {
    const node = containerRef.current;
    if (!node) return;

    const ro = new ResizeObserver(([entry]) => {
      const {width} = entry.contentRect;
      let layout: ILayoutType = 'md';
      if (width < 782) {
        layout = 'xs';
      } else if (width < 1248) {
        layout = 'sm';
      }
      setLayout(layout);
    });

    ro.observe(node);

    return () => {
      ro.unobserve(node);
      ro.disconnect();
    };
  }, []);

  const rightSide =
    mainAreaField === undefined ? null : (
      <div css={$rightSide} data-layout={layout}>
        <div className={'p-mb-2'} css={$mainAreaRow}>
          <div>
            {presetId && (
              <AreaField
                name={`parameters.${mainAreaField[0]}`}
                description={mainAreaField[1]}
                cameraId={cameraId}
                presetId={presetId}
              />
            )}
          </div>
          <div>
            {presetField && (
              <PresetField
                label={locale.commonFieldsLocale.presetId}
                name={`parameters.${presetField[0]}`}
                description={presetField[1]}
                cameraId={formikProps.values.entityId}
              />
            )}
          </div>
        </div>
        {presetId &&
          (otherAreaFields.length === 0 ? null : (
            <div className={'p-mb-2'} css={$otherAreaRow}>
              {otherAreaFields.map((f) => (
                <AreaField
                  key={f[0]}
                  name={`parameters.${f[0]}`}
                  description={f[1]}
                  cameraId={cameraId}
                  presetId={presetId}
                />
              ))}
            </div>
          ))}
        <VisualAreas cameraId={cameraId} presetId={presetId} css={$preview} />
      </div>
    );

  const noRightSideLayout: ILayoutType = 'sm';
  return (
    <div ref={containerRef} style={{width: '100%'}}>
      {layout && (
        <div className={'p-input-filled'} css={$form}>
          <div css={$leftSide} data-layout={rightSide ? layout : noRightSideLayout}>
            <h2>{selectedAssignment ? locale.editTitle : locale.addTitle}</h2>
            <Form css={$grow}>
              <div className={'p-fluid p-formgrid'} css={$fields} data-layout={rightSide ? layout : noRightSideLayout}>
                <StringField name={'title'} description={propertyDescription.properties.title} />
                <RefField
                  disabled={!!selectedAssignment}
                  name={'assignmentTypeId'}
                  description={propertyDescription.properties.assignmentTypeId}
                />
                <RefField name={'priorityId'} description={propertyDescription.properties.priorityId} />
                <ObjectField name={'parameters'} description={restFields} />
              </div>
            </Form>
            {status && (
              <div className={'p-inline-message p-inline-message-warn'} css={$status}>
                <span className="mdi mdi-information" />
                <span>{status}</span>
              </div>
            )}
            {['sm', 'xs'].includes(layout) && rightSide}
            <Form css={$leftSideFooter}>
              <div>
                <Button
                  disabled={isSubmitDisabled}
                  type="submit"
                  label={selectedAssignment ? locale.save : locale.add}
                />
                {!selectedAssignment && (
                  <div>
                    <BooleanField
                      disabled={
                        presetField && currentPresetId !== null
                          ? presetId !== currentPresetId || presetId === null
                          : false
                      }
                      name="startAfterCreate"
                      description={propertyDescription.properties.startAfterCreate}
                    />
                  </div>
                )}
              </div>
              <div>
                <Button
                  className={'p-button-outlined p-button-secondary'}
                  type="button"
                  onClick={onClose}
                  label={!!selectedAssignment ? locale.close : locale.cancel}
                />
              </div>
            </Form>
          </div>
          {['md'].includes(layout) && rightSide}
        </div>
      )}
    </div>
  );
};

// language=SCSS
const $form = css`
  & {
    font-size: 1rem;
    color: var(--text-color);

    display: flex;
    width: min-content;
    padding: var(--form-padding);
    background: var(--surface-b);
    border-radius: var(--border-radius);
  }
  &[data-layout='sm'],
  &[data-layout='xs'] {
    flex-direction: column;
  }
`;

// language=SCSS
const $leftSide = css`
  & {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    width: calc(438rem / var(--bfs));
  }
  & > h2 {
    font-size: var(--font-size-h2);
    font-weight: 500;
    margin: calc(7rem / var(--bfs)) 0 var(--form-padding) 0;
  }
  &[data-layout='sm'] {
    width: calc(722rem / var(--bfs));
  }
  &[data-layout='xs'] {
    width: calc(648rem / var(--bfs));
  }
`;

// language=SCSS
const $grow = css`
  & {
    flex-grow: 1;

    margin-bottom: var(--form-padding);
  }
`;

// language=SCSS
const $rightSide = css`
  & {
    width: calc(720rem / var(--bfs));
  }
  &[data-layout='md'] {
    margin-left: var(--form-padding);
  }
  &[data-layout='sm'] {
    margin-bottom: var(--form-padding);
    width: calc(722rem / var(--bfs));
  }
  &[data-layout='xs'] {
    margin-bottom: var(--form-padding);
    width: calc(648rem / var(--bfs));
  }
`;

// language=SCSS
const $leftSideFooter = css`
  & {
    display: flex;
  }
  & > div:first-of-type {
    flex-grow: 1;

    display: flex;
    align-items: center;

    & > * {
      margin-right: var(--form-field-margin);
    }
  }
  & > div:last-of-type {
    & > * {
      margin-left: var(--form-field-margin);
    }
  }
`;

// language=SCSS
const $status = css`
  & {
    width: 100%;
    margin-bottom: var(--form-field-margin);
    transition: opacity var(--transition-duration) ease;
  }
`;

// language=SCSS
const $fields = css`
  & {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    > .p-field {
      width: calc(50% - var(--form-field-margin));
    }
  }
  &[data-layout='sm'] {
    > .p-field {
      width: calc(100% / 3 - var(--form-field-margin));
    }
  }
`;

const $mainAreaRow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    margin-right: var(--spacer-xs);
  }
  & > div:last-child {
    margin-right: 0;
  }
`;

const $otherAreaRow = css`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: var(--spacer-sm);
  }
  & > div:last-child {
    margin-right: 0;
  }
`;

const $preview = css`
  width: 100%;
`;

const transformErrors = (
  errors: ErrorObject[],
  fieldsLocale: IFieldsLocale,
  locale: {
    required: string;
    valueIs: string;
  }
) => {
  const keyLocale = toKeyLocale(fieldsLocale);
  return errors.reduce((acc, err) => {
    let path = err.dataPath.slice(1).replace(/\//g, '.');
    if (err.keyword === 'required') {
      path = path.length === 0 ? err.params.missingProperty : `${path}.${err.params.missingProperty}`;
      const label = get(keyLocale, path);
      if (label) {
        set(acc, path, `${label} - ${locale.required}`);
      } else {
        console.error('Could not find label', path, keyLocale);
      }
    } else {
      const label = get(keyLocale, path);
      if (label) {
        set(acc, path, `${label} - ${locale.valueIs} ${err.message}`);
      } else {
        console.error('Could not find label', path, keyLocale);
      }
    }
    return acc;
  }, {});
};

type IKeyLocale = {
  [P in string]: string | IKeyLocale;
};

const toKeyLocale = (fieldsLocale: IFieldsLocale): IKeyLocale => {
  const obj = {} as IKeyLocale;
  if (fieldsLocale.properties) {
    Object.entries(fieldsLocale.properties).forEach(([key, value]) => {
      if (typeof value === 'string') {
        obj[key] = value;
      } else if (typeof value === 'object' && value !== null) {
        obj[key] = toKeyLocale(value);
      }
    });
  }
  return obj;
};

const getFirstError = <T,>(formikErrors: FormikErrors<T>): string | undefined => {
  let error: any = formikErrors;
  do {
    error = Object.entries(error)[0]?.[1];
  } while (typeof error === 'object' && error !== null);
  return error;
};
