import React, {FC, createContext, useCallback, useContext, useEffect, useState, useLayoutEffect} from 'react';
import {IEntity, listEntities, notificationSocket} from '@netvision/lib-api';

export const useEntityList = <T extends IEntity>({
  type,
  id,
  attrs,
  q,
  orderBy
}: {
  type: T['type'];
  id?: string;
  attrs?: string;
  q?: string;
  orderBy?: string;
}): [loading: boolean, entities: T[], forceLoad: () => void] => {
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState<T[]>([]);
  const [forceId, setForceId] = useState(0);
  const forceLoad = useCallback(() => {
    setForceId((prev) => prev + 1);
  }, []);
  const entityCache = useContext(ctx);
  useEffect(() => {
    let aborted = false;
    setLoading(true);
    listEntities({
      id,
      type,
      attrs,
      q,
      orderBy,
      limit: 1000,
      keyValues: true
    })
      .then(({results}) => {
        if (aborted) return;

        results.forEach((e) => {
          entityCache.set(JSON.stringify([e.type, e.id]), e);
        });

        setEntities(results as T[]);
      })
      .catch((e) => {
        if (aborted) return;
        setEntities([]);
        console.error(e);
      })
      .finally(() => {
        if (aborted) return;

        setLoading(false);
      });
    return () => {
      aborted = true;
    };
  }, [entityCache, id, attrs, type, q, orderBy, forceId]);
  return [loading, entities, forceLoad];
};

export const useCachedEntity = <T extends IEntity>(type: T['type'], id?: string): T | undefined => {
  const entityCache = (useContext(ctx) as unknown) as IObservableMap<string, T>;
  const [entity, setEntity] = useState<T | undefined>();
  useLayoutEffect(() => {
    if (id) {
      const key = JSON.stringify([type, id]);
      setEntity((entityCache.get(key) as T) ?? undefined);
      return entityCache.subscribe(key, setEntity);
    } else {
      setEntity(undefined);
      return undefined;
    }
  }, [entityCache, type, id]);
  return entity;
};

const ctx = createContext<IObservableMap<string, IEntity>>(null!);

export const EntityCacheProvider: FC = ({children}) => {
  const [cache] = useState<IObservableMap<string, IEntity>>(anObservableMap);
  return <ctx.Provider value={cache}>{children}</ctx.Provider>;
};

interface IObservableMap<K = any, V = any> extends Map<K, V> {
  subscribe(key: K, listener: (value: V | undefined) => void): () => void;
}

class ObservableMap<K, V> extends Map<K, V> implements IObservableMap<K, V> {
  private listeners = createListeners<K, V | undefined>();

  clear() {
    const res = super.clear();
    this.listeners.notify(null, undefined);
    return res;
  }

  delete(key: K): boolean {
    const res = super.delete(key);
    this.listeners.notify(key, undefined);
    return res;
  }

  set(key: K, value: V) {
    const res = super.set(key, value);
    this.listeners.notify(key, value);
    return res;
  }

  subscribe(key: K, listener: (value: V | undefined) => void): () => void {
    this.listeners.add(key, listener);
    return () => {
      this.listeners.remove(key, listener);
    };
  }
}

const anObservableMap = <K, V>(): IObservableMap<K, V> => new ObservableMap();

type IListener<V> = (value: V) => void;

interface IListeners<K, V> {
  add(key: K, listener: IListener<V>): void;
  remove(key: K, listener: IListener<V>): void;
  notify(key: K | null, value: V): void;
}

const createListeners = <K, V>(): IListeners<K, V> => {
  const _clientsMap: Map<K, Set<IListener<V>>> = new Map();
  const add = (key: K, client: IListener<V>) => {
    let clients = _clientsMap.get(key);
    if (!clients) {
      clients = new Set();
      _clientsMap.set(key, clients);
    }
    clients.add(client);
  };
  const notify = (key: K | null, value: V) => {
    if (typeof key === 'string') {
      const clients = _clientsMap.get(key);
      if (clients) {
        clients.forEach((c) => c(value));
      }
    } else {
      _clientsMap.forEach((set) => {
        set.forEach((l) => l(value));
      });
    }
  };
  const remove = (key: K, client: IListener<V>) => {
    const clients = _clientsMap.get(key);
    if (clients) {
      clients.delete(client);
      if (clients.size === 0) {
        _clientsMap.delete(key);
      }
    }
  };
  return {
    add,
    remove,
    notify
  };
};
