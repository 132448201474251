/** @jsx jsx */
import {jsx} from '@emotion/core';
import {useField} from 'formik';
import {FC, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {genElementId} from '../../utils/genElementId';
import {Label} from './common/Label';
import {IPDRefList, IPropertyValue} from '../fieldTypes';
import {useLocale} from '../../providers/LocaleProvider';
import {MultiSelect} from 'primereact/multiselect';
import {useEntityList} from '../../hooks/useEntityList';
import {IFieldProps} from './common/IFieldProps';
import {useFieldLocale} from './common/FieldsLocaleProvider';
import {isEqual} from 'lodash-es';
import {useFormikSetter} from '../../lib/FormikSetterProvider';

export const RefListField: FC<IFieldProps<IPDRefList>> = ({name, description, disabled}) => {
  const {label} = useFieldLocale(name);
  const locale = useLocale();
  const [id] = useState(genElementId);
  const [field, meta] = useField<IPropertyValue<typeof description['type']> | undefined | null>(name);
  const {setFieldValue} = useFormikSetter();
  const ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      const el = ref.current.querySelector('input[type="text"]');
      if (el) {
        el.setAttribute('name', name);
      }
    }
  }, [name]);
  const {entityType, orderBy, filter} = description;
  const [, entities, forceLoad] = useEntityList({type: entityType, q: filter, orderBy});
  const prevDesc = useRef(description);
  useEffect(() => {
    if (!isEqual(prevDesc.current, description)) {
      forceLoad();
    }
    prevDesc.current = description;
  }, [description, forceLoad]);
  const options = useMemo(() => {
    return entities.map((entity) => {
      return {
        key: entity.id,
        value: entity.id,
        label: (entity as any).title ?? (entity as any).name ?? entity.id
      };
    });
  }, [entities]);
  useEffect(() => {
    if (options.length > 0 && field.value && field.value.length === 0) {
      let aborted = false;
      const timeout = setTimeout(() => {
        if (aborted) return;
        setFieldValue(
          name,
          options.slice(0, description.minItems ?? 1).map((o) => o.value.toString())
        );
      });
      return () => {
        aborted = true;
        clearTimeout(timeout);
      };
    }
    return undefined;
    // eslint-disable-next-line
  }, [options]);
  return (
    <div ref={ref} className={'p-field'}>
      <Label htmlFor={id} error={!!meta.error}>
        {label}
      </Label>
      <MultiSelect
        {...field}
        options={options}
        disabled={disabled}
        selectedItemsLabel={locale.selectedItems}
        maxSelectedLabels={2}
        appendTo={document.body}
        css={meta.touched && meta.error ? 'p-error' : undefined}
        id={id}
      />
    </div>
  );
};
