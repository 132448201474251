/** @jsx jsx */
import {jsx} from '@emotion/core';
import {useField} from 'formik';
import {FC} from 'react';
import {genElementId} from '../../utils/genElementId';
import {IPDBoolean, IPropertyValue} from '../fieldTypes';
import {Checkbox} from 'primereact/checkbox';
import {IFieldProps} from './common/IFieldProps';
import {useFieldLocale} from './common/FieldsLocaleProvider';
import {cx} from '../../utils/cx';

export const BooleanField: FC<IFieldProps<IPDBoolean>> = ({name, disabled, description}) => {
  const {label} = useFieldLocale(name);
  const id = genElementId();
  const [field, meta] = useField<IPropertyValue<typeof description['type']> | undefined | null>(name);
  return (
    <div className={'p-field'}>
      <div className={cx(['p-field-checkbox', disabled && 'p-disabled'])}>
        <Checkbox
          checked={!!field.value}
          {...field}
          inputId={id}
          css={meta.touched && meta.error && 'p-error'}
          disabled={disabled}
        />
        <label htmlFor={id} css={meta.error}>
          {label}
          {meta.error && <span>*</span>}
        </label>
      </div>
    </div>
  );
};
