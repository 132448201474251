import {getEntity, createEntity, updateEntityAttributes, IEntity} from '@netvision/lib-api';
import {IAssignment} from './models/IAssignment';

export function fetchAssignment(assignmentId: string): Promise<IAssignment | null> {
  return getEntity({type: 'Assignment', id: assignmentId, keyValues: true})
    .then((res) => {
      if (typeof res === 'object' && res !== null && typeof res.entity === 'object') {
        return res.entity as IAssignment;
      }
      return null;
    })
    .catch((e: any) => {
      console.error(e);
      return null;
    });
}

export type AssignmentToCreate = Pick<
  IAssignment,
  'title' | 'priorityId' | 'entityId' | 'entityType' | 'assignmentTypeId' | 'parameters'
>;

export function createAssigment(data: AssignmentToCreate) {
  return createEntity(
    {
      type: 'Assignment',
      id: 'stub',
      ...data
    },
    {
      keyValues: true
    }
  ).then(({location}: {location: string}): string => {
    const match = location.match(/entities\/([a-z0-9-]{36})\?/)!;
    if (match) {
      return match[1];
    }
    throw Error('Request failed');
  });
}

export type AssignmentToEdit = Pick<IAssignment, 'title' | 'priorityId' | 'parameters'>;

export function patchAssignment(id: IAssignment['id'], data: AssignmentToEdit) {
  const obj: Partial<IAssignment> & IEntity = {
    type: 'Assignment',
    id,
    title: data.title,
    priorityId: data.priorityId,
    parameters: data.parameters
  };
  return updateEntityAttributes(obj, {
    keyValues: true
  });
}

export function startAssignment(id: IAssignment['id']) {
  return updateEntityAttributes(
    {
      id,
      type: 'Assignment',
      startCommand: {}
    },
    {
      keyValues: true
    }
  );
}
