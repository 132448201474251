/** @jsx jsx */
import {jsx} from '@emotion/core';
import {FC, Fragment, useMemo} from 'react';
import {IFieldProps} from './common/IFieldProps';
import {IPDObject, IPropertyDesc} from '../fieldTypes';
import {NumberField} from './NumberField';
import {StringField} from './StringField';
import {BooleanField} from './BooleanField';
import {EnumField} from './EnumField';
import {EnumListField} from './EnumListField';
import {RefField} from './RefField';
import {RefListField} from './RefListField';

export const ObjectField: FC<IFieldProps<IPDObject>> = ({name, description, disabled}) => {
  const fields = useMemo(() => {
    return Object.entries(description.properties).map(([key, value]) => {
      const fieldName = `${name}.${key}`;
      return selectField(fieldName, value, disabled ?? false);
    });
  }, [name, description, disabled]);
  return <Fragment>{fields}</Fragment>;
};

const selectField = (fieldName: string, fd: IPropertyDesc, disabled: boolean) => {
  switch (fd.type) {
    case 'object':
      return <ObjectField key={fieldName} name={fieldName} description={fd} disabled={disabled} />;
    case 'number':
      return <NumberField key={fieldName} name={fieldName} description={fd} disabled={disabled} />;
    case 'string':
      return <StringField key={fieldName} name={fieldName} description={fd} disabled={disabled} />;
    case 'boolean':
      return <BooleanField key={fieldName} name={fieldName} description={fd} disabled={disabled} />;
    case 'enum':
      return <EnumField key={fieldName} name={fieldName} description={fd} disabled={disabled} />;
    case 'enumList':
      return <EnumListField key={fieldName} name={fieldName} description={fd} disabled={disabled} />;
    case 'ref':
      return <RefField key={fieldName} name={fieldName} description={fd} disabled={disabled} />;
    case 'refList':
      return <RefListField key={fieldName} name={fieldName} description={fd} disabled={disabled} />;
    default:
      return null;
  }
};
