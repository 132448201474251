import {IPtzValues} from '../models/IPtzValues';

interface Eq<A> {
  readonly equals: (a: A, b: A) => boolean;
}

const getEqNumber = (threshold: number): Eq<number> => {
  return {
    equals: (a, b) => Math.abs(a - b) < threshold
  };
};

export const getEqPtzValues = (threshold: IPtzValues): Eq<IPtzValues> => {
  const eqEntries = Object.entries(threshold).map(
    ([key, value]) => [key, getEqNumber(value)] as [keyof IPtzValues, Eq<number>]
  );
  return {
    equals: (a, b) => {
      if (a === b) {
        return true;
      }
      return eqEntries.every(([key, eq]) => eq.equals(a[key], b[key]));
    }
  };
};
