export const slick = <T>(obj: T): T => {
  const slicked = {} as Record<string, unknown>;
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value !== 'undefined' && value !== null) {
      if (typeof value === 'object' && !Array.isArray(value)) {
        slicked[key] = slick(value);
      } else {
        slicked[key] = value;
      }
    }
  });
  return slicked as T;
};
