import React, {createContext, FC, useCallback, useContext, useState} from 'react';
import {IEntity} from '@netvision/lib-api';

export interface IPresetArea extends IEntity {
  id: string;
  type: 'PresetArea';
  title: string;
  presetId: string;
  area: Array<[number, number]>;
  areaType: 'Line' | 'Polygon';
  directionType: 'None' | 'Directed' | 'Bidirectional';
  direction: [number, number];
  requiresAdjustment: boolean;
}

const areasReadCtx = createContext<Map<string, IPresetArea>>(null!);
const areasWriteCtx = createContext<(areas: IPresetArea[], areaIds: string[]) => void>(null!);

export const PresetAreasContextProvider: FC = ({children}) => {
  const [areas, setAreas] = useState<Map<string, IPresetArea & {_count: number}>>(aMap);
  const updateAreas = useCallback((areas: IPresetArea[], areaIdsToRemove: string[]) => {
    setAreas((prev) => {
      areaIdsToRemove.forEach((id) => {
        const area = prev.get(id);
        if (area) {
          if (area._count === 1) {
            prev.delete(id);
          } else {
            area._count = area._count - 1;
          }
        }
      });
      areas.forEach((area) => {
        const saved = prev.get(area.id);
        prev.set(area.id, {...area, _count: saved ? saved._count + 1 : 1});
      });
      return new Map(prev);
    });
  }, []);
  return (
    <areasWriteCtx.Provider value={updateAreas}>
      <areasReadCtx.Provider value={areas}>{children}</areasReadCtx.Provider>
    </areasWriteCtx.Provider>
  );
};

export const usePresetAreas = () => useContext(areasReadCtx);
export const useUpdatePresetAreas = () => useContext(areasWriteCtx);

const aMap = () => new Map();
