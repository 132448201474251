import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {useWidgetContext} from '../../Root';
import {CanvasSnapshot, IOverlayParams} from './types';
import {Widget} from '@netvision/lib-widget-renderer';
import {IWidgetProps} from '../../IWidgetProps';

export interface ReadonlyCanvasAdapterProps {
  overlayParams: IOverlayParams;
  initState: {
    itemStore: CanvasSnapshot;
  };
}

type UpdaterType = {
  update: Parameters<Exclude<Widget['onMount'], undefined>>[0];
};

export const ReadonlyCanvasAdapter: FC<ReadonlyCanvasAdapterProps> = ({overlayParams, initState}) => {
  const {mountChildren, areas} = useWidgetContext();
  useLayoutEffect(() => {
    const canvas = getAreasChildren(areas);
    if (canvas) {
      const unmountCanvas = mountChildren(overlayParams.overlayContainer, [
        {
          ...canvas,
          onMount: (update) => {
            setPropsUpdater({
              update
            });
          },
          props: {
            ...canvas.props,
            overlay: overlayParams,
            initState
          }
        }
      ]);
      return () => {
        unmountCanvas();
      };
    }
    return undefined;
    // eslint-disable-next-line
  }, []);
  const [propsUpdater, setPropsUpdater] = useState<UpdaterType | null>(null);
  useEffect(() => {
    if (propsUpdater) {
      propsUpdater.update((oldProps: any) => {
        return {
          ...oldProps,
          overlayParams,
          initState
        };
      });
    }
  }, [propsUpdater, initState, overlayParams]);
  return null;
};

function getAreasChildren(areas: IWidgetProps['areas']): Widget | null {
  let canvas = null;
  areas?.forEach((area) => {
    if (area.name === 'canvas' && area.children && area.children.length > 0) {
      canvas = area.children[0];
    }
  });
  return canvas;
}
