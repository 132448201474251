/** @jsx jsx */
import {jsx} from '@emotion/core';
import {useField} from 'formik';
import {FC, useCallback, useState} from 'react';
import {genElementId} from '../../utils/genElementId';
import {Label} from './common/Label';
import {InputNumber} from 'primereact/inputnumber';
import {IPDNumber, IPropertyValue} from '../fieldTypes';
import {isNumber} from 'lodash-es';
import {IFieldProps} from './common/IFieldProps';
import {useFieldLocale} from './common/FieldsLocaleProvider';
import {useFormikSetter} from '../../lib/FormikSetterProvider';

export const NumberField: FC<IFieldProps<IPDNumber>> = ({name, description, disabled}) => {
  const {label} = useFieldLocale(name);
  const [id] = useState(genElementId);
  const [field, meta] = useField<IPropertyValue<typeof description['type']> | undefined | null>(name);
  const {setFieldValue} = useFormikSetter();
  const onChange = useCallback(
    (e) => {
      const {value} = e;

      if (isNumber(value)) {
        setFieldValue(name, value);
      } else {
        setFieldValue(name, null);
      }
    },
    [name, setFieldValue]
  );
  return (
    <div className={'p-field'}>
      <Label htmlFor={id} error={!!meta.error}>
        {label}
      </Label>
      <InputNumber
        {...field}
        value={field.value ?? undefined}
        onChange={onChange}
        disabled={disabled}
        inputId={id}
        minFractionDigits={description.precision}
        maxFractionDigits={description.precision}
        name={name}
        useGrouping={false}
        css={meta.touched && meta.error ? 'p-error' : undefined}
      />
    </div>
  );
};
