/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {CSSProperties, FC, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {getServerContext, getFiwareHeaders} from '@netvision/lib-api';
import {IOverlayParams} from '../canvas/types';
import {useAsRef} from '../../hooks/useAsRef';

async function buildLivePreviewUrl(cameraId: string) {
  return `${(await getServerContext()).gatewayPath}/vms/v1/streams/${cameraId}/snapshot`;
}

async function buildPresetPreviewUrl(presetId: string) {
  return `${(await getServerContext()).gatewayPath}/cameras/v1/presets/${presetId}`;
}

async function fetchPosterVideo(getPosterUrl: () => Promise<string>, signal?: AbortSignal) {
  const [posterUrl, headers] = await Promise.all([getPosterUrl(), getFiwareHeaders()]);
  return fetch(posterUrl, {
    headers,
    signal
  }).then((res) => {
    if (
      res.status === 200
      // TODO enable when backed will send proper content type
      // && res.headers.get('Content-Type') === 'video/mp4'
    ) {
      return res.blob();
    } else {
      throw Error('Preview cannot be fetched');
    }
  });
}

export const PreviewImage: FC<{
  style?: CSSProperties;
  className?: string;
  cameraId?: string;
  presetId: string | null;
  overlayPointer?: boolean;
  onOverlayReady?: (params: IOverlayParams) => void;
}> = ({cameraId, presetId, className, style, onOverlayReady, overlayPointer = false}) => {
  const [src, setSrc] = useState('');
  useEffect(() => {
    if (!src) return undefined;
    return () => {
      window.URL.revokeObjectURL(src);
    };
  }, [src]);
  useLayoutEffect(() => {
    setSrc('');

    const getPreviewUrl = presetId
      ? () => buildPresetPreviewUrl(presetId)
      : cameraId
      ? () => buildLivePreviewUrl(cameraId)
      : null;

    if (!getPreviewUrl) {
      return undefined;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    fetchPosterVideo(getPreviewUrl, signal)
      .catch((e) => {
        console.error(e);
        return null;
      })
      .then((blob) => {
        if (!signal.aborted && blob) {
          setSrc(window.URL.createObjectURL(blob));
        }
      });

    return () => {
      controller.abort();
    };
  }, [cameraId, presetId]);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const onOverlayReadyRef = useAsRef(onOverlayReady);
  useLayoutEffect(() => {
    const video = videoRef.current;
    const overlay = overlayRef.current;
    if (!video || !overlay) return;

    const onCanPlay = () => {
      onOverlayReadyRef.current?.({
        overlayBase: video,
        overlayContainer: overlay,
        naturalWidth: video.videoWidth,
        naturalHeight: video.videoHeight
      });
    };

    video.addEventListener('canplay', onCanPlay);
    return () => {
      video.removeEventListener('canplay', onCanPlay);
    };
  }, [onOverlayReadyRef]);
  return (
    <div className={className} style={style} css={$root}>
      <video ref={videoRef} src={src || undefined} css={$video} />
      <div ref={overlayRef} css={$overlay} style={{pointerEvents: overlayPointer ? 'all' : 'none'}} />
    </div>
  );
};

const $root = css`
  position: relative;
`;

const $video = css`
  display: block;
  width: 100%;
  background: var(--player-video-bg);
`;

const $overlay = css`
  pointer-events: none;
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
