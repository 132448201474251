import {IAreaType, IDirectionType} from './types';

export const getPresetAreaSchema = (areaTypes: IAreaType[], directionTypes: IDirectionType[]) => {
  return {
    $id: 'PresetAreaCreate',
    type: 'object',
    properties: {
      presetId: {type: 'string'},
      title: {type: 'string', minLength: 3, maxLength: 32, pattern: '^[A-zA-я0-9 .,-]*$'},
      requiresAdjustment: {type: 'boolean'},
      areaType: {type: 'string', enum: areaTypes}
    },
    allOf: [
      {
        if: {
          properties: {
            areaType: {type: 'string', const: 'Polygon'}
          }
        },
        then: {
          properties: {
            area: {
              type: 'array',
              items: {$ref: '#/definitions/point'},
              minItems: 3
            },
            directionType: {
              type: 'string',
              enum: directionTypes
            }
          },
          if: {
            properties: {
              directionType: {
                type: 'string',
                enum: ['Directed', 'Bidirectional']
              }
            }
          },
          then: {
            required: ['presetId', 'title', 'areaType', 'area', 'directionType', 'direction'],
            properties: {
              direction: {$ref: '#/definitions/point'}
            }
          },
          else: {
            required: ['presetId', 'title', 'areaType', 'area', 'directionType']
          }
        }
      },
      {
        if: {
          properties: {
            areaType: {type: 'string', const: 'Line'}
          }
        },
        then: {
          required: ['presetId', 'title', 'areaType', 'area', 'directionType'],
          properties: {
            area: {
              type: 'array',
              items: {$ref: '#/definitions/point'},
              minItems: 2,
              maxItems: 2
            },
            directionType: {
              type: 'string',
              enum: directionTypes
            }
          }
        }
      }
    ],
    definitions: {
      point: {
        type: 'array',
        items: {
          type: 'number'
        },
        minItems: 2,
        maxItems: 2
      }
    }
  };
};

// export const PresetAreaEditSchema = {
//   $id: 'PresetAreaCreate',
//   type: 'object',
//   additionalProperties: false,
//   properties: {
//     id: {type: 'string'},
//     presetId: {type: 'string'},
//     title: {type: 'string'},
//     requiresAdjustment: {type: 'boolean', default: false},
//     areaType: {type: 'string', enum: ['Line', 'Polygon']},
//     directionType: {
//       type: 'string',
//       enum: ['None', 'Directed', 'Bidirectional']
//     }
//   },
//   allOf: [
//     {
//       if: {
//         properties: {
//           areaType
//         }
//       }
//     }
//   ],
//   definitions: {
//     point: {
//       type: 'array',
//       items: {
//         type: 'number'
//       },
//       minItems: 2,
//       maxItems: 2
//     }
//   }
// };
