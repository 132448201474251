/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import React, {FC, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {genElementId} from '../../../utils/genElementId';
import {useField} from 'formik';
import {Dropdown} from 'primereact/dropdown';
import {useEntityList} from '../../../hooks/useEntityList';
import {IPDRef} from '../../../dynamic-form/fieldTypes';
import {Button} from 'primereact/button';
import {cx} from '../../../utils/cx';
import {useLocale} from '../../../providers/LocaleProvider';
import {encodeQValue} from '../../../ngsi-codec';
import {ICameraPreset} from '../../../models/ICameraPreset';
import {isEqual} from 'lodash-es';
import {PresetEditor} from '../../presetEditor/PresetEditor';
import {CalibrationEditor} from '../../calibrate/CalibrationEditor';
import {getPTZData} from '../../../api-ptz';
import {getEqPtzValues} from '../../../lib/getEqPtzValues';
import {useFormikSetter} from '../../../lib/FormikSetterProvider';
import {useCurrentPreset} from '../CurrentPresetProvider';

const C_defaultCameraThreshold = {
  x: 0.001,
  y: 0.001,
  zoom: 0.003
};

export const PresetField: FC<{
  label: string;
  name: string;
  description: IPDRef;
  cameraId: string;
}> = ({label, name, description, cameraId}) => {
  const locale = useLocale();
  const [id] = useState(genElementId);
  const [field, meta] = useField(name);
  const {setFieldValue} = useFormikSetter();
  const ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      const el = ref.current.querySelector('input[type="text"]');
      if (el) {
        el.setAttribute('name', name);
      }
    }
  }, [name]);
  const {entityType, orderBy, filter} = description;
  const [, entities, forceLoad] = useEntityList<ICameraPreset>({
    type: entityType as 'CameraPreset',
    q: cameraId ? encodeQValue(filter ?? '', 'cameraId', '==', [cameraId]) : filter,
    orderBy
  });
  const prevDesc = useRef(description);
  useEffect(() => {
    if (!isEqual(prevDesc.current, description)) {
      forceLoad();
    }
    prevDesc.current = description;
  }, [description, forceLoad]);
  const options = useMemo(() => {
    return entities.map((entity) => {
      return {
        key: entity.id,
        value: entity.id,
        label: (entity as any).title ?? (entity as any).name ?? entity.id
      };
    });
  }, [entities]);
  const currentPreset = entities.find((e) => e.id === field.value);
  const [loadingPtzData, setLoadingPtzData] = useState(false);
  const [, setCurrentPreset] = useCurrentPreset();
  useEffect(() => {
    if (options.length > 0 && field.value === null) {
      let aborted = false;
      setLoadingPtzData(true);
      setPresetsSupported(false);
      getPTZData(cameraId).then((data) => {
        if (!aborted) {
          setLoadingPtzData(false);
          if (data) {
            const eqPosition = getEqPtzValues(data?.ptzSettings.threshold ?? C_defaultCameraThreshold);
            const matchingPreset = entities.find((p) => eqPosition.equals(data?.status?.position, p?.status?.position));
            if (matchingPreset) {
              setFieldValue(name, matchingPreset.id);
              setCurrentPreset(matchingPreset.id);
            } else {
              setFieldValue(name, options[0].value);
              setCurrentPreset(null);
            }
            setPresetsSupported(true);
          } else {
            setFieldValue(name, options[0].value);
            setCurrentPreset(null);
            setPresetsSupported(false);
          }
        }
      });
      return () => {
        aborted = true;
      };
    } else {
      setLoadingPtzData(false);
      return undefined;
    }
    // eslint-disable-next-line
  }, [field.value, options]);

  const [presetsSupported, setPresetsSupported] = useState(false);
  const [presetEditorOpen, setPresetEditorOpen] = useState(false);
  const [calibrateOpen, setCalibrateOpen] = useState(false);

  return (
    <div ref={ref} css={$inline}>
      <label htmlFor={id} css={$label}>
        {label}
        {loadingPtzData ? (
          <span>
            {' '}
            <i className={'mdi mdi-loading mdi-spin'} />
          </span>
        ) : (
          !!meta.error && <span css={$required}>{' *'}</span>
        )}
      </label>

      <Dropdown
        disabled={loadingPtzData}
        {...field}
        options={options}
        id={id}
        appendTo={document.body}
        className={meta.touched && meta.error ? 'p-error' : undefined}
        css={$dd}
      />

      <Button
        disabled={!presetsSupported}
        className={cx(['p-button-outlined', options.length !== 0 && 'p-button-secondary'])}
        icon={options.length === 0 ? 'mdi mdi-20px mdi-plus' : 'mdi mdi-20px mdi-pencil'}
        tooltip={options.length === 0 ? locale.createPreset : locale.editPreset}
        tooltipOptions={{position: 'left'}}
        onClick={() => setPresetEditorOpen(true)}
      />
      <Button
        disabled={!currentPreset}
        className={cx([
          'p-button-outlined',
          !currentPreset?.groundCalibration ? 'p-button-primary' : 'p-button-secondary'
        ])}
        icon={'mdi mdi-20px mdi-tune-variant'}
        tooltip={locale.calibrate}
        tooltipOptions={{position: 'left'}}
        onClick={() => setCalibrateOpen(true)}
      />
      {presetEditorOpen && (
        <PresetEditor
          cameraId={cameraId}
          onHide={() => {
            forceLoad();
            setPresetEditorOpen(false);
          }}
        />
      )}
      {currentPreset && calibrateOpen && (
        <CalibrationEditor
          preset={currentPreset}
          onHide={() => {
            forceLoad();
            setCalibrateOpen(false);
          }}
        />
      )}
    </div>
  );
};

const $inline = css`
  display: flex;
  align-items: center;
  & > * {
    margin-left: var(--spacer-xs);
  }
  & > *:first-child {
    margin-left: 0;
  }
`;

const $label = css`
  position: relative;
  font-size: calc(16rem / var(--bfs));
  font-weight: 500;
  color: var(--text-color);
`;

const $required = css`
  color: var(--error-color);
`;

const $dd = css`
  width: calc(150rem / var(--bfs));
`;
