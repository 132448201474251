export interface IOverlayParams {
  /**
   * Element that contains overlay.
   */
  overlayContainer: HTMLElement;
  /**
   * This element is used to calculate sizes.
   * ResizeObserver tracks changes
   */
  overlayBase: HTMLElement;
  /**
   * This sizes are used as a maximum value for coordinates.
   * Also this is used to to place canvas properly,
   * in case when aspect ratio of video or image does not correspond to it's container aspect ratio (black borders).
   */
  naturalHeight: number;
  naturalWidth: number;
}

export enum ToolName {
  movePolygon = 'movePolygon',
  addPolygon = 'addPolygon',
  addLine = 'addLine',
  moveLine = 'moveLine'
}

export interface CanvasSnapshot {
  polygonStore: {
    items: Polygon[];
    editableLimit: number;
    nameDefault?: string;
  };
  lineStore: {
    items: Line[];
    editableLimit: number;
    nameDefault?: string;
    directedDefault?: boolean;
  };
}

export interface Polygon {
  id: string;
  editable: boolean;
  name?: string;
  direction?: {x: number; y: number};
  directed?: boolean;
  style?: {
    strokeColor: string;
    fillColor?: string;
  };
  points: Array<Point & {index: number}>;
}

export interface Line {
  id: string;
  editable: boolean;
  name?: string;
  style?: {
    strokeColor: string;
  };
  start: Point;
  end: Point;
  directed: boolean;
}

interface Point {
  x: number;
  y: number;
}
