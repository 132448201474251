/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {FC} from 'react';

export const Label: FC<{htmlFor?: string; error?: boolean}> = ({htmlFor, children, error = false}) => {
  return (
    <label htmlFor={htmlFor}>
      {children}
      {error && <span css={$required}>{' *'}</span>}
    </label>
  );
};

// language=SCSS
const $required = css`
  & {
    position: absolute;
    margin-left: calc(5rem / var(--bfs));
    color: var(--error-color);
  }
`;
