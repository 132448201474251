export type ILocale = typeof defaultLocale;

const primeCalendar = {
  firstDayOfWeek: 1,
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  dayNamesShort: ['Вос', 'Пон', 'Вто', 'Сре', 'Чет', 'Пят', 'Суб'],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
  ],
  monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Нов', 'Дек'],
  today: 'Сегодня',
  clear: 'Очистить',
  weekHeader: 'Неделя'
};

const commonFieldsLocale: Record<string, string> = {
  startAfterCreate: 'Запустить сразу',
  title: 'Название',
  assignmentTypeId: 'Тип',
  priorityId: 'Приоритет',
  presetId: 'Позиция камеры'
};

const areaTypesLocale = {
  areaTypes: {
    Line: 'Линия',
    Polygon: 'Полигон'
  },
  directed: 'с направлением',
  required: 'Требуется',
  directedTypes: {
    None: 'Без направления',
    Directed: 'Одностороннее',
    Bidirectional: 'Двустороннее'
  }
};

const areaEditor = {
  createTitle: 'Новая область',
  editTitle: 'Изменить',
  save: 'Сохранить',
  create: 'Создать',
  cancel: 'Отмена',
  delete: 'Удалить',
  deleteDetails: 'Удалить область?',
  fields: {
    title: 'Название',
    areaType: 'Тип области',
    area: 'Координаты',
    direction: 'Направление',
    directionType: 'Тип направления'
  },
  typeEnumLabels: {
    Polygon: 'Полигон',
    Line: 'Линия'
  },
  errors: {
    area: 'Выделите область на изображении',
    direction: 'Необходимо задать направление',
    directionType: 'Неподходящий тип направления'
  }
};

const presetEditor = {
  header: 'Изменить предустановки'
};

export const defaultLocale = {
  addTitle: 'Добавить аналитику',
  editTitle: 'Редактировать аналитику',
  canvasLabel: 'Зона действия',
  areaTypeLabel: 'Направление учета',
  controlObjectLabel: 'Контролируемый объект',
  thresholdLabel: 'Чувствительность',
  sensorTypeLabel: 'Тип датчика',
  syncModeLabel: 'Режим синхронизации',
  previewGenerationDelayLabel: 'Задержка создания превью',
  hostLabel: 'Хост',
  userLabel: 'Пользователь',
  passwordLabel: 'Пароль',
  connectionTimeoutLabel: 'Таймаут соединения',
  stateHistoryTTLLabel: 'Время хранения состояния',
  syncFromLabel: 'Дата начала синхронизации',
  syncToLabel: 'Дата окончания синхронизации',
  useSectionSpeedLabel: 'Получение средней скорости',
  mergeAssignmentIdLabel: 'Объединение событий с',
  required: 'обязательное поле',
  valueIs: 'значение',
  depends: 'Вместе с "{{0}}" должно быть указано "{{1}}"',
  nameRequired: 'Введите название аналитики',
  pointsRequired: 'Выделите зону действия аналитики',
  lineRequired: 'Установите линию подсчета пересечений',
  thresholdError: 'Чувствительность должна принимать значение от 0 до 1',
  controlTypeError: 'Выберите хотя бы один тип контролируемого объекта',
  unavailableValue: 'Неподходящее значение поля',
  add: 'Добавить',
  cancel: 'Отмена',
  close: 'Закрыть',
  startAfterCreate: 'Запустить сразу',
  startAfterCreateTooltip: 'Аналитика будет запущена сразу после добавления',
  save: 'Сохранить',
  sending: 'Отправка данных...',
  sendingSuccess: 'Успешно',
  sendingError: 'Неизвестная ошибка',
  selectedItems: '{0} выбрано',
  emptySelection: 'Не выбрано',
  createArea: 'Создать новую область',
  delete: 'Удалить',
  selectAll: 'Выбрать всё',
  calibratePreset: 'Калибровка',
  createPreset: 'Создать новую',
  editPreset: 'Изменить позиции',
  calibrate: 'Калибровка',
  areaEditor,
  presetEditor,
  areaTypesLocale,
  commonFieldsLocale,
  primeCalendar
};
