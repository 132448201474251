/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {Dialog} from 'primereact/dialog';
import {useDialogVisible} from '../../hooks/useDialogVisible';
import {useLocale} from '../../providers/LocaleProvider';
import {FC, Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import {genElementId} from '../../utils/genElementId';
import {CanvasSnapshot, ToolName, Line, Polygon, IOverlayParams} from '../canvas/types';
import {WriteableCanvasAdapter} from '../canvas/WriteableCanvasAdapter';
import {IPDEnum, IPDString} from '../../dynamic-form/fieldTypes';
import {Form, Formik, FormikConfig, FormikProps, useFormikContext} from 'formik';
import {EnumField, FieldsLocaleProvider, IFieldsLocale, StringField} from '../../dynamic-form/fields';
import Ajv, {ErrorObject} from 'ajv';
import {isEqual, isString} from 'lodash-es';
import {createEntity, updateEntityAttributes} from '@netvision/lib-api';
import {IPresetArea} from '../../hooks/usePresetAreas';
import {useAsRef} from '../../hooks/useAsRef';
import {useAjvLocale} from '../../hooks/useAjvLocale';
import {PreviewImage} from '../preview/PreviewImage';
import {isKeyOf} from '../../utils/isKeyOf';
import {IAreaType, IDirectionType} from './types';
import {getPresetAreaSchema} from './getPresetAreaSchema';
import {FormikSetterProvider} from '../../lib/FormikSetterProvider';

type IFieldDesc = {
  title: IPDString;
  areaType: IPDEnum;
};

type IFormValues = {
  presetId: string;
  title: string;
  areaType: IAreaType;
  area: Array<[number, number]>;
  directionType: 'None' | 'Directed' | 'Bidirectional';
  direction?: [number, number];
};

export interface IAreaEditorProps {
  presetArea?: IPresetArea;
  requiredAreaTypes?: IAreaType[];
  requiredDirectionTypes?: IDirectionType[];
  cameraId: string;
  presetId: string;
  onClose: (requiresRefresh?: boolean) => void;
}

export const AreaEditor: FC<IAreaEditorProps> = ({
  presetArea,
  requiredAreaTypes,
  requiredDirectionTypes,
  cameraId,
  presetId,
  onClose: _onHide
}) => {
  const ajvLocale = useAjvLocale('ru');
  const id = presetArea?.id;
  const [dialogId] = useState(genElementId);
  const [visible, onHide] = useDialogVisible(_onHide);
  const locale = useLocale().areaEditor;

  let allowedAreaTypes: IAreaType[] = useMemo(() => ['Polygon', 'Line'], []);
  let allowedDirectionTypes: IDirectionType[] = useMemo(() => ['None', 'Bidirectional', 'Directed'], []);
  if (presetArea) {
    allowedAreaTypes = [presetArea.areaType];
    allowedDirectionTypes = [presetArea.directionType];
  }
  if (requiredAreaTypes && requiredAreaTypes.length > 0) {
    allowedAreaTypes = requiredAreaTypes;
  }
  if (requiredDirectionTypes && requiredDirectionTypes.length > 0) {
    allowedDirectionTypes = requiredDirectionTypes;
  }

  const fieldDesc = useMemo((): IFieldDesc => {
    return {
      title: {
        type: 'string',
        minLength: 3,
        maxLength: 32,
        pattern: '^[A-zA-я0-9 .,-]*$'
      },
      areaType: {
        type: 'enum',
        enum: allowedAreaTypes
      }
    };
  }, [allowedAreaTypes]);

  const fieldsLocale = useMemo((): IFieldsLocale => {
    return {
      properties: locale.fields,
      enumLabels: {
        areaType: allowedAreaTypes.map((t) => locale.typeEnumLabels[t])
      }
    };
  }, [locale, allowedAreaTypes]);

  const [areaType, setAreaType] = useState<IAreaType>(allowedAreaTypes[0]);

  const prevValuesRef = useRef<IFormValues | undefined>(undefined);

  const onChange = useCallback((formikProps: FormikProps<IFormValues>) => {
    setAreaType(formikProps.values.areaType);
    prevValuesRef.current = formikProps.values;
  }, []);

  const fieldInitialValues = useMemo((): IFormValues => {
    if (presetArea) {
      return {
        presetId,
        title: presetArea.title,
        areaType: presetArea.areaType,
        area: presetArea.area,
        directionType: presetArea.directionType,
        direction: presetArea.direction
      };
    } else {
      const prev = prevValuesRef.current;
      return {
        presetId,
        title: prev?.title ?? '',
        areaType,
        area: [],
        directionType: areaType === 'Line' ? 'Bidirectional' : 'None'
      };
    }
  }, [presetArea, presetId, areaType, prevValuesRef]);

  const validate = useMemo((): FormikConfig<IFormValues>['validate'] => {
    const ajv = new Ajv({allErrors: true});
    const validate = ajv.compile(getPresetAreaSchema(allowedAreaTypes, allowedDirectionTypes));
    const transformErrors = (errors: ErrorObject[]) => {
      ajvLocale(errors);
      const formatErrors: [string, string][] = [];
      const requiredErrors: [string, string][] = [];
      errors.forEach((err) => {
        let format;
        let key;
        if (err.keyword === 'required') {
          key = err.params.missingProperty;
          format = false;
        } else {
          key = err.dataPath.slice(1);
          format = true;
        }
        let entry: [string, string];
        if (isKeyOf(key, locale.errors)) {
          entry = [key, locale.errors[key]];
        } else {
          entry = [key, `"${locale.fields[key as keyof typeof locale.fields] ?? key}" - ${err.message}`];
        }
        if (format) {
          formatErrors.push(entry);
        } else {
          requiredErrors.push(entry);
        }
      });
      return Object.fromEntries([...formatErrors, ...requiredErrors]);
    };
    return (values) => {
      if (validate(values)) {
        return {};
      } else {
        return transformErrors(validate.errors ?? []);
      }
    };
  }, [locale, ajvLocale, allowedAreaTypes, allowedDirectionTypes]);
  const onSubmit = useMemo(
    (): FormikConfig<IFormValues>['onSubmit'] => async (values) => {
      if (id === undefined) {
        return createEntity(
          {
            id: 'stub',
            type: 'PresetArea',
            title: values.title,
            presetId: values.presetId,
            areaType: values.areaType,
            direction: values.direction,
            directionType: values.directionType,
            area: values.area
          },
          {keyValues: true}
        ).then(() => {
          onHide(true);
        });
      } else {
        return updateEntityAttributes(
          {
            id,
            type: 'PresetArea',
            title: values.title,
            area: values.area,
            direction: values.direction,
            directionType: values.directionType
          },
          {keyValues: true}
        ).then(() => {
          onHide(true);
        });
      }
    },
    [id, onHide]
  );
  return (
    <Dialog
      id={dialogId}
      css={$dialog}
      appendTo={document.body}
      showHeader={false}
      visible={visible}
      closable={false}
      dismissableMask={false}
      modal={true}
      onHide={onHide}>
      <FieldsLocaleProvider value={fieldsLocale}>
        <Formik
          enableReinitialize
          validateOnChange={true}
          initialValues={fieldInitialValues}
          validate={validate}
          onSubmit={onSubmit}>
          <Form>
            <FormikSetterProvider>
              <DialogContent
                fieldLocale={fieldsLocale}
                fieldDesc={fieldDesc}
                presetArea={presetArea}
                cameraId={cameraId}
                presetId={presetId}
                onClose={onHide}
                onChange={onChange}
              />
            </FormikSetterProvider>
          </Form>
        </Formik>
      </FieldsLocaleProvider>
    </Dialog>
  );
};

const EMPTY_AREA = [] as [number, number][];

type IDialogContentProps = IAreaEditorProps & {
  fieldDesc: IFieldDesc;
  fieldLocale: IFieldsLocale;
  onChange: (values: FormikProps<IFormValues>) => void;
};

const DialogContent: FC<IDialogContentProps> = ({presetArea, cameraId, presetId, onClose, fieldDesc, onChange}) => {
  const formikProps = useFormikContext<IFormValues>();

  useEffect(() => {
    onChange(formikProps);
  }, [formikProps, onChange]);

  const id = presetArea?.id;
  const locale = useLocale();
  const isEdit = typeof id !== 'undefined';

  const areaType = formikProps.values.areaType;

  const prevAreaType = useRef(areaType);
  useLayoutEffect(() => {
    prevAreaType.current = areaType;
  }, [areaType]);
  const areaTypeChanged = prevAreaType.current !== areaType;

  const area = areaTypeChanged ? EMPTY_AREA : formikProps.values.area;
  useLayoutEffect(() => {
    const newValues: IFormValues = {
      ...formikProps.values,
      area,
      directionType: formikProps.values.areaType === 'Line' ? 'Bidirectional' : 'None'
    };
    if (newValues.direction) {
      delete newValues.direction;
    }
    formikProps.setValues(newValues);
    // eslint-disable-next-line
  }, [areaTypeChanged]);

  const direction = formikProps.values.direction;

  const directed = (() => {
    switch (formikProps.values.directionType) {
      case 'Directed':
        return true;
      case 'Bidirectional':
        return false;
      case 'None':
      default:
        return undefined;
    }
  })();
  const title = formikProps.values.title;

  const itemStore = useMemo(() => {
    const polygons = [];
    const lines = [];
    switch (areaType) {
      case 'Line':
        try {
          const line = presetAreaToLine('', title, area, directed ?? false);
          line && lines.push(line);
        } catch (e) {
          console.error('Could not convert PresetArea to Polygon');
        }
        break;
      case 'Polygon':
        try {
          const polygon = presetAreaToPolygon('', title, area, direction, directed);
          polygon && polygons.push(polygon);
        } catch (e) {
          console.error('Could not convert PresetArea to Polygon');
        }
        break;
    }
    return {
      polygonStore: {
        items: polygons,
        editableLimit: areaType === 'Polygon' ? 1 : 0
      },
      lineStore: {
        items: lines,
        editableLimit: areaType === 'Line' ? 1 : 0
      }
    };
  }, [areaType, area, title, directed, direction]);

  const itemStoreRef = useAsRef(itemStore);

  const initState = useMemo(() => {
    return {
      activeTool:
        areaType === 'Line'
          ? isEdit
            ? ToolName.moveLine
            : ToolName.addLine
          : isEdit
          ? ToolName.movePolygon
          : ToolName.addPolygon,
      itemStore: itemStoreRef.current
    };
    // re-init on areaType change
    // eslint-disable-next-line
  }, [areaType]);

  const valuesRef = useAsRef(formikProps.values);
  const setValuesRef = useAsRef(formikProps.setValues);
  const onUpdate = useCallback(
    (snapshot: CanvasSnapshot) => {
      const values = valuesRef.current;
      let area: Array<[number, number]> = [];
      let direction: [number, number] | undefined = undefined;
      let directionType: 'None' | 'Directed' | 'Bidirectional' = 'None';
      switch (values.areaType) {
        case 'Polygon':
          const polygon = snapshot.polygonStore.items[0];
          if (polygon) {
            area = polygon.points
              .slice()
              .sort((a, b) => a.index - b.index)
              .map(({x, y}) => [x, y]);
            direction = polygon.direction ? [polygon.direction.y, polygon.direction.x] : undefined;
            directionType = (() => {
              switch (polygon.directed) {
                case false:
                  return 'Bidirectional';
                case true:
                  return 'Directed';
                case undefined:
                default:
                  return 'None';
              }
            })();
          } else {
            directionType = 'None';
          }
          break;
        case 'Line':
          const line = snapshot.lineStore.items[0];
          if (line) {
            area = [line.start, line.end].map(({x, y}) => [x, y]);
            directionType = (() => {
              switch (line.directed) {
                case true:
                  return 'Directed';
                case false:
                case undefined:
                default:
                  return 'Bidirectional';
              }
            })();
          } else {
            directionType = 'Bidirectional';
          }
          break;
      }
      const updated: [keyof IFormValues, unknown][] = [];
      if (!isEqual(area, values.area)) {
        updated.push(['area', area]);
      }
      if (!isEqual(direction, values.direction)) {
        updated.push(['direction', direction]);
      }
      if (!isEqual(directionType, values.directionType)) {
        updated.push(['directionType', directionType]);
      }
      if (updated.length > 0) {
        const newValues = {
          ...values
        };
        updated.forEach(([key, value]) => {
          if (value === undefined) {
            delete newValues[key];
          } else {
            newValues[key] = value as never;
          }
        });
        setValuesRef.current(newValues);
      }
    },
    [valuesRef, setValuesRef]
  );

  const errors = Object.entries(formikProps.errors ?? {}).reduce((acc, [key, value]) => {
    if (isString(value)) {
      acc.push(value);
    }
    return acc;
  }, [] as string[]);
  const status = errors[0] ?? formikProps.status;

  const [overlayParams, setOverlayParams] = useState<IOverlayParams | null>(null);
  const [controlsNode, setControlsNode] = useState<HTMLElement | null>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    const node = controlsRef.current;
    if (node) {
      setControlsNode(node);
    }
  }, []);
  const isSubmitDisabled =
    formikProps.isSubmitting || formikProps.isValidating || !formikProps.isValid || !formikProps.dirty;
  return (
    <Fragment>
      <div css={$header}>
        <Title title={isEdit ? locale.areaEditor.editTitle : locale.areaEditor.createTitle}>
          <Button
            type={'button'}
            onClick={() => onClose(false)}
            className={'p-button-outlined p-button-secondary'}
            label={locale.cancel}
          />
          <Button
            disabled={isSubmitDisabled}
            type={'submit'}
            label={isEdit ? locale.areaEditor.save : locale.areaEditor.create}
          />
        </Title>
        <div>
          <div className={'p-input-filled p-fluid p-formgrid'} css={$fields}>
            <StringField name={'title'} description={fieldDesc.title} />
            <EnumField disabled={!!id} name={'areaType'} description={fieldDesc.areaType} />
            {status && (
              <div className={'p-inline-message p-inline-message-warn'} css={$status}>
                <span className="mdi mdi-information" />
                <span>{status}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div css={$canvas}>
        <PreviewImage css={$underlay} overlayPointer={true} presetId={presetId} onOverlayReady={setOverlayParams} />
        {!!overlayParams && !!controlsNode && (
          <WriteableCanvasAdapter
            tooltipPosition={'top'}
            controlsContainer={controlsNode}
            overlayParams={overlayParams}
            initState={initState}
            currentSnapshot={itemStore}
            onUpdate={onUpdate}
          />
        )}
      </div>
      <div css={$controls} ref={controlsRef} />
    </Fragment>
  );
};

const Title: FC<{title: string; className?: string}> = ({title, className, children}) => {
  return (
    <div className={className} css={$title}>
      <h2>{title}</h2>
      <div>{children}</div>
    </div>
  );
};

const $title = css`
  width: 100%;
  display: flex;
  align-items: center;
  > h2 {
    font-size: var(--font-size-h2);
    font-weight: 500;
    margin: 0;
  }
  > div {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;

    > * {
      margin-left: var(--spacer-sm);
    }

    > *:first-child {
      margin-left: 0;
    }
  }
`;

const $dialog = css`
  width: calc(960rem / var(--bfs));
  max-height: unset;
  height: calc(100vh - 60rem / var(--bfs));
`;

const $header = css`
  color: var(--text-color);
  padding: var(--spacer);
  > * {
    margin-bottom: var(--spacer);
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;

const $fields = css`
  & {
    position: relative;
    display: flex;

    > .p-field {
      height: calc(70rem / var(--bfs));
      width: calc(210rem / var(--bfs));
    }
  }
`;

const $canvas = css``;
const $controls = css`
  padding: var(--spacer-xs) var(--spacer-xs);
`;

const $underlay = css`
  height: calc(100vh - (200rem + 60rem + 60rem) / var(--bfs));
  > video {
    height: 100%;
    object-fit: contain;
  }
`;

// language=SCSS
const $status = css`
  & {
    width: calc(100% - (210 * 2 + 30 * 3) * 1rem / var(--bfs));
    margin-right: var(--form-field-margin);
    margin-bottom: var(--form-field-margin);
  }
`;

const presetAreaToPolygon = (
  id: string,
  title: string,
  area: Array<[number, number]>,
  direction: [number, number] | undefined,
  directed: boolean | undefined
): Polygon | undefined => {
  if (area.length > 0) {
    // area.direction
    return {
      id: id,
      editable: true,
      name: title,
      direction: !!direction ? {x: direction[1], y: direction[0]} : undefined,
      directed: directed,
      points: area.map(([x, y], index) => ({index, x, y}))
    };
  }
  return undefined;
};

const presetAreaToLine = (
  id: string,
  title: string,
  area: Array<[number, number]>,
  directed: boolean
): Line | undefined => {
  const [start, end] = area.map(([x, y], index) => ({index, x, y}));
  if (start && end) {
    return {
      id: id,
      editable: true,
      name: title,
      start,
      end,
      directed
    };
  }
  return undefined;
};
