/** @jsx jsx */
import {jsx} from '@emotion/core';
import {useField} from 'formik';
import {FC, useCallback, useState} from 'react';
import {InputText} from 'primereact/inputtext';
import {genElementId} from '../../utils/genElementId';
import {Label} from './common/Label';
import {IPDString, IPropertyValue} from '../fieldTypes';
import {IFieldProps} from './common/IFieldProps';
import {useFieldLocale} from './common/FieldsLocaleProvider';
import {useFormikSetter} from '../../lib/FormikSetterProvider';
import {isString} from 'lodash-es';

export const StringField: FC<IFieldProps<IPDString>> = ({name, description, disabled}) => {
  const {label} = useFieldLocale(name);
  const [id] = useState(genElementId);
  const [field, meta] = useField<IPropertyValue<typeof description['type']> | undefined | null>(name);
  const {setFieldValue} = useFormikSetter();
  const onChange = useCallback(
    (e) => {
      const value = e.target.value;
      const {pattern} = description;
      if (isString(pattern)) {
        const regEx = new RegExp(pattern);
        if (regEx.test(value)) {
          setFieldValue(name, value);
        }
      } else {
        setFieldValue(name, value);
      }
    },
    [description, name, setFieldValue]
  );
  return (
    <div className={'p-field'}>
      <Label htmlFor={id} error={!!meta.error}>
        {label}
      </Label>
      <InputText
        {...field}
        value={field.value ?? ''}
        onChange={onChange}
        disabled={disabled}
        id={id}
        className={meta.touched && meta.error ? 'p-error' : undefined}
      />
    </div>
  );
};
