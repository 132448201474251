const STATEMENT_DELIMITER = ';';
const LIST_DELIMITER = ',';
const STRING_QUOTE = "'";

// Values supported by parser
type Value<Op extends EBinaryOP> = Exclude<ReturnType<typeof valueParsers[Op]>, undefined>;
type BasicValue = string | number | boolean | null;
// type Value = BasicValue | Array<BasicValue>

const UnaryOp = {
  '': '',
  '!': '!'
} as const;

type EUnaryOP = keyof typeof UnaryOp;

const unaryOps = Object.values(UnaryOp);

const BinaryOp = {
  '~=': '~=', // str|regex
  '==': '==', // int "string" boolean null
  '!=': '!=', // int "string" boolean
  '>': '>', // int
  '>=': '>=', // int
  '<': '<', // int
  '<=': '<=' // int
} as const;

const binaryOps = Object.values(BinaryOp);

type EBinaryOP = keyof typeof BinaryOp;

type UnaryStatement<Op extends EUnaryOP = EUnaryOP> = {
  // op
  op: Op;
  // attr
  attr: string;
};

type BinaryStatement<Op extends EBinaryOP = EBinaryOP> = {
  // op
  op: Op;
  // attr
  attr: string;
  // value
  value: Value<Op>;
};

type Statement<Op extends EBinaryOP | EUnaryOP = EBinaryOP | EUnaryOP> = Op extends EBinaryOP
  ? BinaryStatement<Op>
  : Op extends EUnaryOP
  ? UnaryStatement<Op>
  : never;

export const isBinaryStatement = (st: BinaryStatement | UnaryStatement): st is BinaryStatement => st.op in BinaryOp;

type Q = Array<BinaryStatement | UnaryStatement>;

const packEnum = (value: unknown): string | undefined => {
  if (isArray(value)) {
    const res = value.map(packBasic).filter(isString);
    return res.length > 0 ? res.sort().join(LIST_DELIMITER) : undefined;
  }
  console.error('packEnum - Should be array');
  return undefined;
};

const removeQuotes = (s: string) => s.replace(new RegExp(STRING_QUOTE, 'g'), '');

const packBasic = (value: unknown): string | undefined => {
  if (value === null) {
    return 'null';
  } else if (isString(value)) {
    return `'${removeQuotes(value)}'`;
  } else if (isBoolean(value) || isNumber(value)) {
    return value.toString();
  }
  console.error(`packBasic - Unexpected value type ${typeof value} ${value}`);
  return undefined;
};

const packComparison = (value: unknown): string | undefined => {
  if (isNumber(value)) {
    return value.toString();
  }
  console.error('packNumber - Unexpected value type');
  return undefined;
};

const packMatch = (value: unknown): string | undefined => {
  if (isString(value)) {
    return value.length > 0 ? value : undefined;
  }
  console.error('packMatch - Unexpected value type', typeof value, value);
  return undefined;
};

const valuePackers = {
  '~=': packMatch,
  '==': packEnum,
  '!=': packEnum,
  '>': packComparison,
  '>=': packComparison,
  '<': packComparison,
  '<=': packComparison
};

// select operator's packer and
const packStatement = (st: BinaryStatement | UnaryStatement): string | undefined => {
  if ('value' in st) {
    const {op, attr, value} = st;
    const packer = valuePackers[op];
    const packed = packer(value);
    if (packed !== undefined) {
      return [attr, op, packed].join('');
    }
  } else {
    const {op, attr} = st;
    if (op in UnaryOp && attr.length > 0) {
      return [op, attr].join('');
    }
  }
  return undefined;
};

const parseEnum = (v: string): BasicValue[] | undefined => {
  const res = splitWith(v, LIST_DELIMITER, STRING_QUOTE).map((v) => {
    if (v === 'null') {
      return null;
    } else if (v === 'true') {
      return true;
    } else if (v === 'false') {
      return false;
    } else {
      const num = Number(v);
      if (!isNaN(num)) {
        return num;
      } else {
        return removeQuotes(v);
      }
    }
  });
  if (res.length === 0) {
    return undefined;
  } else {
    return res;
  }
};

const parseComparison = (v: string): number | undefined => {
  const num = Number(v);
  if (isNaN(num)) {
    console.error(`Could not parse number from ${v}`);
    return undefined;
  }
  return num;
};

const parseMatch = (v: string): string | undefined => {
  return v.length > 0 ? v : undefined;
};

const valueParsers = {
  '~=': parseMatch,
  '==': parseEnum,
  '!=': parseEnum,
  '>': parseComparison,
  '>=': parseComparison,
  '<': parseComparison,
  '<=': parseComparison
};

const parseStatement = (s: string): BinaryStatement | UnaryStatement | undefined => {
  // try every binary operator and pick corresponding parser for value
  for (let i = 0; i < binaryOps.length; i++) {
    const op = binaryOps[i];
    const splits = s.split(op);
    if (splits.length === 2) {
      const [attr, raw] = splits;
      const parser = valueParsers[op];
      const value = parser(raw);
      if (value === undefined) {
        return undefined;
      }
      return {op, attr, value};
    }
  }
  // try every unary operator
  for (let i = 0; i < unaryOps.length; i++) {
    const op = unaryOps[i];
    if (s.startsWith(op)) {
      return {op, attr: s.slice(op.length)};
    }
  }
  return undefined;
};

export const encodeQ = (statements: Q): string | undefined => {
  if (statements.length > 0) {
    const filtered = statements
      .slice()
      .sort((a, b) => a.attr.localeCompare(b.attr, 'en') || a.op.localeCompare(b.op, 'en'))
      .map(packStatement)
      .filter(isString);
    if (filtered.length > 0) {
      return filtered.join(STATEMENT_DELIMITER);
    }
  }
  return undefined;
};

export const decodeQ = (s: unknown): Q => {
  if (isString(s) && s.length > 0) {
    return splitWith(s, STATEMENT_DELIMITER, STRING_QUOTE)
      .map(parseStatement)
      .filter((st): st is Statement => !isUndefined(st));
  } else {
    return [];
  }
};

export const encodeQValue = <Op extends EBinaryOP>(
  q: string,
  attr: string,
  op: Op,
  value: Value<Op>
): string | undefined => {
  const parsed: Q = decodeQ(q).filter((st) => !(st.op === op && st.attr === attr));
  const st: BinaryStatement<Op> = {op, attr, value};
  parsed.push(st);
  return encodeQ(parsed);
};

export const decodeQValue = <Op extends EBinaryOP>(q: string, attr: string, op: Op): Value<Op> | undefined => {
  const st = decodeQ(q).find((st): st is BinaryStatement<Op> => st.op === op && st.attr === attr);
  if (isUndefined(st)) {
    return undefined;
  } else {
    return st.value;
  }
};

export const normalizeQ = (q: string): string | undefined => encodeQ(decodeQ(q));

// splits string with delimiter, but skips delimiter in quotes context
const splitWith = (s: string, delimiter: string, quote: string): string[] => {
  const indexes = [];
  let isEscaped = false;

  for (let i = 0; i < s.length; i++) {
    if (s[i] === quote) {
      isEscaped = !isEscaped;
    } else if (s[i] === delimiter && !isEscaped) {
      indexes.push(i);
    }
  }

  if (indexes.length === 0) {
    return [s];
  } else {
    return indexes.reduce((acc, value, index, arr) => {
      if (index === 0) {
        acc.push(s.slice(0, value));
      }
      if (index === arr.length - 1) {
        acc.push(s.slice(value + 1));
      } else {
        acc.push(s.slice(value + 1, arr[index + 1]));
      }
      return acc;
    }, [] as string[]);
  }
};

const isArray = (v: unknown): v is Array<unknown> => Array.isArray(v);
const isString = (v: unknown): v is string => typeof v === 'string';
const isBoolean = (v: unknown): v is boolean => typeof v === 'boolean';
const isNumber = (v: unknown): v is number => typeof v === 'number';
const isUndefined = (v: unknown): v is undefined => typeof v === 'undefined';
