import {useEffect, useState} from 'react';
// @ts-ignore
import ajvLocaleRu from 'ajv-i18n/localize/ru';

export const useAjvLocale = (lang: ILang) => {
  const [ajvLocale, setAjvLocale] = useState(() => ajvLocaleRu);
  // TODO support multiple locales
  // useEffect(() => {
  //   if (lang === 'ru') {
  //     console.log(ajvLocaleRu);
  //     setAjvLocale(ajvLocaleRu);
  //     return undefined;
  //   } else {
  //     let aborted = false;
  //     import(ajvImports[lang]).then((locale) => {
  //       setAjvLocale
  //     }).catch(() => setAjvLocale(ajvLocaleRu));
  //     return () => {
  //       aborted = true;
  //     };
  //   }
  // }, [lang]);
  return ajvLocale;
};

type ILang = 'ru';

const ajvImports: Record<Exclude<ILang, 'ru'>, any> = {
  // @ts-ignore
  // @ts-ignore
  // en: import('ajv-i18n/localize/en').then((m) => m.default)
};
