/** @jsx jsx */
import {css, jsx} from '@emotion/core';
import {FC, Fragment, ReactElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useField} from 'formik';
import {IPDRef, IPDRefList} from '../../../dynamic-form/fieldTypes';
import {useEntityList} from '../../../hooks/useEntityList';
import {useAsRef} from '../../../hooks/useAsRef';
import {useLocale} from '../../../providers/LocaleProvider';
import {createPortal} from 'react-dom';
import {Button} from 'primereact/button';
import {cx} from '../../../utils/cx';
import {Checkbox, CheckboxProps} from 'primereact/checkbox';
import {genElementId} from '../../../utils/genElementId';
import {decodeQValue} from '../../../ngsi-codec';
import {IPresetArea, useUpdatePresetAreas} from '../../../hooks/usePresetAreas';
import {AreaEditor} from '../../areaEditor/AreaEditor';
import {useDialogVisible} from '../../../hooks/useDialogVisible';
import {Dialog} from 'primereact/dialog';
import {deleteEntity} from '@netvision/lib-api';
import {useFieldLocale} from '../../../dynamic-form/fields/common/FieldsLocaleProvider';
import {isEqual} from 'lodash-es';
import {isArray, isString} from '../../../utils/basicValidators';
import {useFormikSetter} from '../../../lib/FormikSetterProvider';

type IAreaType = 'Polygon' | 'Line';
type IDirectionType = 'None' | 'Directed' | 'Bidirectional';

export function AreaField(props: {name: string; description: IPDRefList | IPDRef; cameraId: string; presetId: string}) {
  const {name, description, cameraId, presetId} = props;
  const locale = useLocale();
  const {label} = useFieldLocale(name);
  const {setFieldValue} = useFormikSetter();
  const updatePresetAreas = useUpdatePresetAreas();
  const [field, meta] = useField<string | string[] | null>(name);
  const {orderBy, filter} = description;
  const [, entities, forceLoad] = useEntityList<IPresetArea>({
    type: 'PresetArea',
    q: `presetId==${presetId}`,
    orderBy
  });
  const requiredAreaAttrs = useMemo(() => {
    let areaType, directionType;
    if (filter) {
      const decoded = decodeURIComponent(filter);
      areaType = decodeQValue(decoded, 'areaType', '==');
      directionType = decodeQValue(decoded, 'directionType', '==');
    }
    return {
      areaType: (areaType ?? ['Line', 'Polygon']) as IAreaType[],
      directionType: (directionType ?? ['None', 'Directed', 'Bidirectional']) as IDirectionType[]
    };
  }, [filter]);
  const prevDesc = useRef(description);
  useEffect(() => {
    if (!isEqual(prevDesc.current, description)) {
      forceLoad();
    }
    prevDesc.current = description;
  }, [description, forceLoad]);
  const options = useMemo(() => {
    return entities
      .map((entity) => {
        return {
          value: entity.id,
          label: ((entity as any).title ?? (entity as any).name ?? entity.id).toString(),
          disabled:
            !requiredAreaAttrs.areaType.includes(entity.areaType) ||
            !requiredAreaAttrs.directionType.includes(entity.directionType)
        };
      })
      .sort((a, b) => Number(a.disabled) - Number(b.disabled));
  }, [requiredAreaAttrs, entities]);
  const enabledOptions = options.filter(({disabled}) => !disabled);
  const [dialogParams, setDialogParams] = useState<IDialogParams | null>(null);
  const onAdd = useCallback(() => {
    setDialogParams({type: 'add'});
  }, []);
  const onEdit = useCallback((id: string | number) => {
    setDialogParams({type: 'edit', id});
  }, []);
  const onDelete = useCallback((id: string | number) => {
    setDialogParams({type: 'delete', id});
  }, []);
  const footer = (
    <Fragment>
      <AreaTypeInfo
        warn={enabledOptions.length === 0}
        areaType={requiredAreaAttrs.areaType}
        directionType={requiredAreaAttrs.directionType}
      />
      <Button
        className={'p-button-outlined'}
        label={locale.createArea}
        style={{width: '100%'}}
        icon={'mdi mdi-20px mdi-shape-polygon-plus'}
        onClick={onAdd}
      />
    </Fragment>
  );
  const inputValue: string[] = (isString(field.value) ? [field.value] : field.value ?? []).filter((v) =>
    options.some(({value}) => value === v)
  );
  const onChange = useCallback(
    (inputValue: Array<string | number>) => {
      if (isArray(isString)(inputValue)) {
        let value: undefined | string[] | null | string;
        switch (description.type) {
          case 'refList':
            value = inputValue;
            break;
          case 'ref':
            if (inputValue.length === 0) {
              value = null;
            } else {
              value = inputValue[0];
            }
            break;
        }
        if (value !== undefined) {
          setFieldValue(name, value);
        }
      }
    },
    [name, setFieldValue, description]
  );
  // const prevValue = useRef(inputValue);
  useEffect(() => {
    const set = new Set(inputValue);
    const areas = entities.filter((en) => set.has(en.id)) as IPresetArea[];
    updatePresetAreas(areas, []);

    return () => {
      updatePresetAreas([], inputValue);
    };
    // eslint-disable-next-line
  }, [inputValue]);
  useEffect(() => {
    onChange(inputValue);
    // eslint-disable-next-line
  }, [options]);
  return (
    <Fragment>
      <InlineMounter disabled={!presetId} label={label} error={!!meta.error}>
        <InlineMultiSelect
          value={inputValue}
          options={options}
          footer={footer}
          onChange={onChange}
          onEdit={onEdit}
          onDelete={onDelete}
          maxItems={description.type === 'refList' ? description.maxItems : 1}
        />
      </InlineMounter>
      {dialogParams?.type === 'add' && (
        <AreaEditor
          requiredAreaTypes={requiredAreaAttrs.areaType}
          requiredDirectionTypes={requiredAreaAttrs.directionType}
          cameraId={cameraId}
          presetId={presetId}
          onClose={(requiresRefresh?: boolean) => {
            setDialogParams(null);
            requiresRefresh && forceLoad();
          }}
        />
      )}
      {dialogParams?.type === 'edit' && (
        <AreaEditor
          presetArea={(entities as IPresetArea[]).find(({id}) => id === dialogParams.id)}
          cameraId={cameraId}
          presetId={presetId}
          onClose={(requiresRefresh?: boolean) => {
            setDialogParams(null);
            requiresRefresh && forceLoad();
          }}
        />
      )}
      {dialogParams?.type === 'delete' && (
        <DeleteModal
          id={dialogParams.id as string}
          onClose={(requiresRefresh?: boolean) => {
            setDialogParams(null);
            requiresRefresh && forceLoad();
          }}
        />
      )}
    </Fragment>
  );
}

type IDialogParams =
  | {
      type: 'add';
    }
  | {
      type: 'edit';
      id: string | number;
    }
  | {
      type: 'delete';
      id: string | number;
    };

const InlineMounter: FC<{label: string; error?: boolean; disabled?: boolean}> = ({
  children,
  label,
  error,
  disabled
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  useLayoutEffect(() => {
    const node = ref.current;
    if (isOpen && node) {
      const div = document.createElement('div');
      div.style.position = 'absolute';
      div.style.top = '100%';
      div.style.left = '0';
      div.style.zIndex = '1';
      node.appendChild(div);
      setPortalNode(div);
      let pass = true;
      const onInside = () => {
        pass = true;
      };
      const onOutside = () => {
        if (pass) {
          pass = false;
        } else {
          setOpen(false);
        }
      };
      return () => {
        div.removeEventListener('click', onInside);
        document.body.removeEventListener('click', onOutside);
        div.remove();
        setPortalNode(null);
      };
    }
    return undefined;
  }, [isOpen]);

  return (
    <div ref={ref} css={$inlineMounter}>
      <div>
        <div css={$inlineMounterLabel}>
          {label}
          <span css={$required} style={{opacity: error ? 1 : 0}}>
            {' *'}
          </span>
        </div>
        <div>
          <Button
            disabled={disabled}
            icon={'mdi mdi-20px mdi-menu-down'}
            className={cx(['p-button-outlined', 'p-button-secondary'])}
            css={$toggleButton}
            onClick={() => setOpen((val) => !val)}
          />
        </div>
      </div>
      {portalNode && createPortal(children, portalNode)}
    </div>
  );
};

// language=SCSS
const $inlineMounter = css`
  & {
    position: relative;
    > div:nth-child(1) {
      display: flex;
      align-items: center;

      > * {
        margin-right: calc(10rem / var(--bfs));
      }
      > *:last-child {
        margin-right: 0;
      }
    }
    > div:nth-child(2) {
      position: absolute;
      z-index: 3;
    }
  }
`;

// language=SCSS
const $inlineMounterLabel = css`
  & {
    position: relative;
    font-size: calc(16rem / var(--bfs));
    font-weight: 500;
    color: var(--text-color);
  }
`;

const $toggleButton = css`
  width: calc(40rem / var(--bfs));
  height: calc(30rem / var(--bfs));
`;

// language=SCSS
const $required = css`
  & {
    color: var(--error-color);
  }
`;

const InlineMultiSelect: FC<{
  value: (string | number)[];
  options: Array<{label: string; value: string | number; disabled: boolean}>;
  onChange: (value: (string | number)[]) => void;
  onEdit?: (itemValue: string | number) => void;
  onDelete?: (itemValue: string | number) => void;
  onClose?: () => void;
  maxItems?: number;
  footer?: ReactElement;
}> = ({value, options, onChange, footer, onEdit, onDelete, onClose, maxItems}) => {
  const locale = useLocale();
  const onChangeRef = useAsRef(onChange);
  const enabledOptions = options.filter(({disabled}) => !disabled);
  const selectAll = useMemo((): ICheckboxFieldProps['onChange'] => {
    return (e) => {
      onChangeRef.current(e.checked ? enabledOptions.map(({value}) => value).slice(0, maxItems) : []);
    };
  }, [maxItems, enabledOptions, onChangeRef]);

  const valueAsSet = new Set(value);
  const valueAsSetRef = useAsRef(valueAsSet);

  const handleChange = useMemo((): ICheckboxFieldProps['onChange'] => {
    return (e) => {
      let value;
      const name = e.target.name;
      if (name.startsWith('n')) {
        value = Number(name.slice(1));
      } else {
        value = name.slice(1);
      }
      if (maxItems === 1) {
        if (e.checked) {
          onChangeRef.current([value]);
        } else {
          onChangeRef.current([]);
        }
      } else {
        const set = valueAsSetRef.current;
        if (set.size < (maxItems ?? Infinity)) {
          if (e.checked) {
            set.add(value);
          } else {
            set.delete(value);
          }
          onChangeRef.current([...set]);
        }
      }
    };
  }, [maxItems, onChangeRef, valueAsSetRef]);
  return (
    <div css={$inlineMultiSelect}>
      {(maxItems ?? Infinity) > 1 ? (
        <div css={$multiHeader}>
          <CheckboxField
            label={locale.selectAll}
            disabled={enabledOptions.length === 0}
            checked={enabledOptions.length > 0 && options.every(({value}) => valueAsSet.has(value))}
            onChange={selectAll}>
            {!!onClose && (
              <div css={$buttons}>
                <Button
                  onClick={onClose}
                  className={'p-button-text p-button-secondary'}
                  icon={'mdi mdi-18px mdi-close'}
                />
              </div>
            )}
          </CheckboxField>
        </div>
      ) : (
        <div css={$singleHeader} />
      )}
      <ul>
        {options.map(({label, value, disabled}) => {
          return (
            <li key={value}>
              <CheckboxField
                disabled={disabled}
                label={label}
                name={typeof value === 'number' ? `n${value}` : `s${value}`}
                checked={valueAsSet.has(value)}
                onChange={handleChange}>
                {!disabled && (!!onEdit || !!onDelete) ? (
                  <div css={$buttons}>
                    {!!onEdit && (
                      <Button
                        onClick={() => onEdit(value)}
                        className={'p-button-text p-button-secondary'}
                        icon={'mdi mdi-18px mdi-pencil'}
                      />
                    )}
                    {!!onDelete && (
                      <Button
                        onClick={() => onDelete(value)}
                        className={'p-button-text p-button-secondary'}
                        icon={'mdi mdi-18px mdi-delete'}
                      />
                    )}
                  </div>
                ) : null}
              </CheckboxField>
            </li>
          );
        })}
      </ul>
      <div css={$footer}>{footer}</div>
    </div>
  );
};

type ICheckboxFieldProps = CheckboxProps & {label: string};

export const CheckboxField: FC<ICheckboxFieldProps> = ({label, children, ...rest}) => {
  const [id] = useState(genElementId);
  return (
    <div className={['p-field-checkbox', rest.disabled && 'p-disabled'].filter(Boolean).join(' ')} css={$checkbox}>
      <Checkbox {...rest} id={id} />
      <label htmlFor={id}>{label}</label>
      {children}
    </div>
  );
};

const $checkbox = css`
  color: var(--text-color);
  font-weight: 500;
`;

// language=SCSS
const $inlineMultiSelect = css`
  & {
    width: calc(300rem / var(--bfs));
    background: var(--surface-b);
    border-radius: var(--border-radius);
    padding: 0 calc(20rem / var(--bfs)) calc(20rem / var(--bfs));

    box-shadow: var(--overlay-shadow);

    > ul {
      max-height: calc(180rem / var(--bfs));
      overflow: auto;

      list-style: none;
      padding: 0;
      margin: 0;
      font-weight: 500;
      color: var(--text-color-secondary);

      position: relative;

      display: block;
      flex-wrap: wrap;

      > li {
        padding: 0;
        margin: 0 0 calc(20rem / var(--bfs)) 0;
      }
      > li:last-child {
        margin: 0;
      }
    }
  }
`;

const $multiHeader = css`
  > div:first-of-type {
    padding: calc(20rem / var(--bfs)) 0;

    margin-bottom: calc(20rem / var(--bfs));
    border-bottom: calc(1rem / var(--bfs)) solid var(--secondary-color);
    color: var(--text-color-secondary);
    font-weight: 500;
  }
`;

const $singleHeader = css`
  margin-bottom: calc(19rem / var(--bfs));
  height: calc(1rem / var(--bfs));
`;

const $buttons = css`
  div:hover > & {
    opacity: 1;
  }
  opacity: 0;

  transition: opacity var(--transition-duration) ease;

  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  > button.p-button-text {
    width: calc(20rem / var(--bfs));
    height: calc(20rem / var(--bfs));
    padding: 0;
    margin-left: calc(10rem / var(--bfs));
  }
`;

const $footer = css`
  margin-top: var(--spacer-sm);
`;

export const DeleteModal: FC<{id: string; onClose: (requiresRefresh?: boolean) => void}> = ({id, onClose: _onHide}) => {
  const locale = useLocale();
  const [visible, onHide] = useDialogVisible(_onHide);
  const footer = (
    <Fragment>
      <Button
        className={'p-button-danger'}
        label={locale.delete}
        onClick={() => {
          return deleteEntity({id, type: 'PresetArea'})
            .then(() => onHide(true))
            .catch((e: unknown) => {
              console.error(e);
              return e;
            });
        }}
      />
      <Button className={'p-button-secondary p-button-outlined'} label={locale.cancel} onClick={() => onHide(false)} />
    </Fragment>
  );
  // const toastRef = useToastRef()
  return (
    <Dialog
      appendTo={document.body}
      header={locale.areaEditor.delete}
      visible={visible}
      onHide={onHide}
      footer={footer}>
      <div style={{padding: '0 var(--form-padding)'}}>{locale.areaEditor.deleteDetails}</div>
    </Dialog>
  );
};

const AreaTypeInfo: FC<{warn: boolean; areaType: IAreaType[]; directionType: IDirectionType[]}> = ({
  warn,
  areaType,
  directionType
}) => {
  const locale = useLocale().areaTypesLocale;
  return (
    <div className={cx(['p-inline-message', warn ? 'p-inline-message-warn' : 'p-inline-message-plain'])} css={$info}>
      <span className="mdi mdi-information" />
      <span>{`${locale.required}: ${areaType
        .map((t) => (locale.areaTypes as Record<string, string>)[t] ?? t)
        .join(', ')} ${directionType.includes('None') ? '' : locale.directed}`}</span>
    </div>
  );
};

const $info = css`
  width: 100%;
  margin-bottom: var(--spacer-sm);
`;
