import React, {FC, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useWidgetContext} from '../../Root';
import {IWidgetProps} from '../../IWidgetProps';
import {Widget} from '@netvision/lib-widget-renderer';

export interface ILivePlayerProps {
  cameraId: string;
}

type UpdaterType = {
  update: Parameters<Exclude<Widget['onMount'], undefined>>[0];
};

export const LivePlayerAdapter: FC<ILivePlayerProps & JSX.IntrinsicElements['div']> = ({cameraId, ...rest}) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const {mountChildren, areas} = useWidgetContext();
  useLayoutEffect(() => {
    const node = nodeRef.current;
    const live = getAreasChildren(areas);
    if (!node || !live) return undefined;
    return mountChildren(node, [
      {
        ...live,
        onMount: (update) => {
          setPropsUpdater({
            update
          });
        },
        props: {
          ...live.props,
          cameraId
        }
      }
    ]);
    // eslint-disable-next-line
  }, []);
  const [propsUpdater, setPropsUpdater] = useState<UpdaterType | null>(null);
  useEffect(() => {
    if (propsUpdater) {
      propsUpdater.update((oldProps: any) => {
        return {
          ...oldProps,
          cameraId
        };
      });
    }
  }, [propsUpdater, cameraId]);
  return <div ref={nodeRef} {...rest} />;
};

function getAreasChildren(areas: IWidgetProps['areas']): Widget | null {
  let canvas = null;
  areas?.forEach((area) => {
    if (area.name === 'live' && area.children && area.children.length > 0) {
      canvas = area.children[0];
    }
  });
  return canvas;
}
