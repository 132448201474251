import React, {FC, useEffect, useLayoutEffect, useState} from 'react';
import {useWidgetContext} from '../../Root';
import {CanvasSnapshot, IOverlayParams, ToolName} from './types';
import {Widget} from '@netvision/lib-widget-renderer';
import {IWidgetProps} from '../../IWidgetProps';

export interface WriteableCanvasAdapterProps {
  controlsContainer: HTMLElement;
  tooltipPosition?: string;
  overlayParams: IOverlayParams;
  initState: {
    activeTool: ToolName;
    itemStore: CanvasSnapshot;
  };
  currentSnapshot?: CanvasSnapshot;
  onUpdate?(snapshot: CanvasSnapshot): void;
}

type UpdaterType = {
  update: Parameters<Exclude<Widget['onMount'], undefined>>[0];
};

export const WriteableCanvasAdapter: FC<WriteableCanvasAdapterProps> = ({
  controlsContainer,
  tooltipPosition,
  overlayParams,
  initState,
  currentSnapshot,
  onUpdate
}) => {
  const {mountChildren, areas} = useWidgetContext();
  useLayoutEffect(() => {
    const canvas = getAreasChildren(areas);
    if (canvas) {
      return mountChildren(overlayParams.overlayContainer, [
        {
          ...canvas,
          onMount: (update) => {
            setPropsUpdater({
              update
            });
          },
          props: {
            ...canvas.props,
            overlay: overlayParams,
            controls: {controlsContainer, tooltipPosition},
            initState
          }
        }
      ]);
    }
    return undefined;
    // eslint-disable-next-line
  }, []);
  const [propsUpdater, setPropsUpdater] = useState<UpdaterType | null>(null);
  useEffect(() => {
    if (propsUpdater) {
      propsUpdater.update((oldProps: any) => {
        return {
          ...oldProps,
          overlayParams,
          initState,
          onUpdate,
          currentSnapshot
        };
      });
    }
  }, [propsUpdater, initState, overlayParams, currentSnapshot, onUpdate]);
  return null;
};

function getAreasChildren(areas: IWidgetProps['areas']): Widget | null {
  let canvas = null;
  areas?.forEach((area) => {
    if (area.name === 'canvas' && area.children && area.children.length > 0) {
      canvas = area.children[0];
    }
  });
  return canvas;
}
