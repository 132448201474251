import {IPDString} from '../fieldTypes';
import {JSONSchemaType} from 'ajv';

export const getStringSchema = (fd: IPDString, required: boolean): JSONSchemaType<string> => {
  return {
    type: 'string',
    minLength: required ? Math.max(1, fd.minLength ?? 1) : fd.minLength,
    maxLength: fd.maxLength,
    pattern: fd.pattern
  };
};
