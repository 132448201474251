import {IPDNumber} from '../fieldTypes';
import {JSONSchemaType} from 'ajv';

export const getNumberSchema = (fd: IPDNumber): JSONSchemaType<number> => {
  return {
    type: fd.precision === 0 ? 'integer' : 'number',
    multipleOf: fd.multipleOf,
    maximum: fd.maximum,
    exclusiveMaximum: fd.exclusiveMaximum,
    minimum: fd.minimum,
    exclusiveMinimum: fd.exclusiveMinimum
  };
};
