import React, {createContext, FC, useCallback, useContext, useRef} from 'react';
import {useFormikContext} from 'formik';
import {cloneDeep, set} from 'lodash-es';

export type IFormikSetter = {
  setFieldValue<T>(name: string, value: T): void;
};

const ctx = createContext<IFormikSetter>(null!);

// due to the fact that formik's setFieldValue is prone to race conditions, there is a custom implementation

export const FormikSetterProvider: FC = ({children}) => {
  const formikContext = useFormikContext<Record<string, unknown>>();
  const valuesRef = useRef(formikContext.values);
  valuesRef.current = formikContext.values;
  const setValuesRef = useRef(formikContext.setValues);
  setValuesRef.current = formikContext.setValues;
  const setField = useCallback(<T,>(name: string, value: T) => {
    const copy = cloneDeep(valuesRef.current);
    set(copy, name, value);
    setValuesRef.current(copy);
  }, []);
  return <ctx.Provider value={{setFieldValue: setField}}>{children}</ctx.Provider>;
};

export const useFormikSetter = () => {
  return useContext(ctx);
};
