import React, {createContext, FC, useContext, useMemo} from 'react';

export type IFieldsLocale = {
  properties?: Record<string, string | IFieldsLocale>;
  enumLabels?: Record<string, string[]>;
};

const ctx = createContext<IFieldsLocale>(null!);

export const FieldsLocaleProvider: FC<{value: IFieldsLocale}> = ({value, children}) => {
  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export const useFieldLocale = (name: string): {label: string; enumLabels?: string[]} => {
  const ctxValue = useContext(ctx);
  const path = useMemo(() => name.split('.'), [name]);
  const key = path[path.length - 1];
  let value: IFieldsLocale = ctxValue;
  for (let i = 0; i < path.length - 1; i++) {
    const propName = path[i];
    if ('properties' in value && typeof value.properties === 'object' && value.properties !== null) {
      const next = value.properties[propName];
      if (typeof next !== 'object') {
        return {label: key};
      }
      value = next;
    } else {
      return {label: key};
    }
  }
  const label = typeof value.properties === 'object' && value.properties[key];
  return {
    label: typeof label === 'string' ? label : key,
    enumLabels: value.enumLabels?.[key]
  };
};
