/** @jsx jsx */
import {jsx} from '@emotion/core';
import {useField} from 'formik';
import {FC, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {genElementId} from '../../utils/genElementId';
import {Label} from './common/Label';
import {Dropdown} from 'primereact/dropdown';
import {IPDEnum, IPropertyValue} from '../fieldTypes';
import {IFieldProps} from './common/IFieldProps';
import {useFieldLocale} from './common/FieldsLocaleProvider';
import {useFormikSetter} from '../../lib/FormikSetterProvider';

export const EnumField: FC<IFieldProps<IPDEnum>> = ({name, description, disabled}) => {
  const {label, enumLabels} = useFieldLocale(name);
  const [id] = useState(genElementId);
  const [field, meta] = useField<IPropertyValue<typeof description['type']> | undefined | null>(name);
  const {setFieldValue} = useFormikSetter();
  const ref = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (ref.current) {
      const el = ref.current.querySelector('input[type="text"]');
      if (el) {
        el.setAttribute('name', name);
      }
    }
  }, [name]);
  const options = useMemo(() => {
    const {enum: _enum} = description;
    return _enum.map((value, index) => {
      return {
        key: value,
        value: value,
        label: enumLabels?.[index] ?? value
      };
    });
  }, [description, enumLabels]);
  useEffect(() => {
    if (options.length > 0 && field.value === null) {
      let aborted = false;
      const timeout = setTimeout(() => {
        if (aborted) return;
        setFieldValue(name, options[0].value);
      });
      return () => {
        aborted = true;
        clearTimeout(timeout);
      };
    }
    return undefined;
    // on options change only
    // eslint-disable-next-line
  }, [options]);
  return (
    <div ref={ref} className={'p-field'}>
      <Label htmlFor={id} error={!!meta.error}>
        {label}
      </Label>
      <Dropdown
        {...field}
        disabled={disabled}
        options={options}
        appendTo={document.body}
        className={meta.touched && meta.error ? 'p-error' : undefined}
        id={id}
      />
    </div>
  );
};
